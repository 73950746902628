import { useEffect, useState } from 'react';

import { IRemoteConfig } from './types';

import Countly from './index';

function useCountlyEvent(event: string): void {
  useEffect(() => {
    Countly.EventStart(event);

    return () => {
      Countly.EventEnd(event);
    };
  }, [event]);
}

function useCountlyConfig() {
  const [countlyConfig, setCountlyConfig] = useState<null | IRemoteConfig>(null);
  const [isConfigLoading, setIsConfigLoading] = useState(true);

  useEffect(() => {
    Countly.GetRemoteConfig((config: null | IRemoteConfig) => {
      setCountlyConfig(config);
      setIsConfigLoading(false);
    });
  }, []);

  return { countlyConfig, isConfigLoading };
}

export { useCountlyEvent, useCountlyConfig };
