import { useCallback, useEffect, useState } from 'react';

const useCookieBot = () => {
  const [consents, setConsents] = useState<ICookieBotConsent | null>(null);

  const consentGrantedCallback = useCallback((callback?: (args: ICookieBotConsent) => void) => {
    // https://www.cookiebot.com/en/developer/
    // The CookiebotOnAccept event is triggered if the user accepts the use of cookies.
    // It is also triggered if the user has consented at an earlier visit to the website.
    window.addEventListener(
      'CookiebotOnAccept',
      function () {
        setConsents(window.Cookiebot.consent);
        callback?.(window.Cookiebot.consent);
      },
      false
    );
  }, []);

  useEffect(() => {
    consentGrantedCallback();
  }, [consentGrantedCallback]);

  return { consents };
};

export default useCookieBot;
