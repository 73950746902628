import { useIntl } from 'react-intl';

import ModalBody from './ModalBody';

const EmailOk = (props: { onButtonClick: Function }) => {
  const { formatMessage } = useIntl();

  const header = formatMessage({
    id: 'Modals.EmailOk.header',
    description: 'Header for EmailOk modal',
    defaultMessage: 'Geschafft! Wir freuen uns auf Ihren Besuch!',
  });

  const message = formatMessage({
    id: 'Modals.EmailOk.message',
    description: 'Message for EmailOk modal',
    defaultMessage:
      'Ihren Termin können Sie jederzeit in der App bearbeiten. Bei Fragen oder Anliegen sind wir ebenfalls ganz einfach per App erreichbar.',
  });

  const ctaButtonLabel = formatMessage({
    id: 'Modals.EmailOk.ctaButton',
    description: 'Cta for EmailOk modal',
    defaultMessage: 'Schließen',
  });

  return (
    <ModalBody
      header={header}
      message={message}
      primaryButtonLabel={ctaButtonLabel}
      onPrimaryButtonClick={() => props.onButtonClick('primary')}
    />
  );
};

export default EmailOk;
