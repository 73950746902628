import { useEffect } from 'react';

import { trackEvent } from '../../utils/analyticsUtils';

import { useSomethingWentWrongTranslations } from './index.translations';

import './styles.scss';

import ErrorImg from '../../assets/images/appointment-acute.png';

type TProps = {
  title?: string;
  description?: string;
  error: Error | null;
  parentScreen?: string;
};
const SomethingWentWrong = ({ title, description, error, parentScreen }: TProps) => {
  const translations = useSomethingWentWrongTranslations();

  useEffect(() => {
    trackEvent({
      category: 'Error',
      action: `Something went wrong - ${parentScreen ?? 'Unknown screen'}`,
      name: error?.message ?? 'Unknown error',
    });
  }, [error?.message, parentScreen]);

  return (
    <div data-testid='something-went-wrong' className='something-went-wrong-container'>
      <img src={ErrorImg} alt='error' />
      <div>
        <h2>{title ?? translations.title}</h2>
        <p>{description ?? translations.description}</p>
      </div>
    </div>
  );
};

export default SomethingWentWrong;
