import { webApiBaseUrl } from 'config';
import api from 'services/api';

import { Source } from 'interfaces/Source';
import { ERetiredOrFamilyInsuredOptions } from 'pages/Booking/InsuranceSelection/type';
import { Salutation, UserTitle } from 'pages/Booking/UserData/types';

export enum EConsentType {
  TERMS_CONDITIONS = 'TERMS_CONDITIONS',
  RECALLS = 'RECALLS',
  MESSENGER = 'MESSENGER',
}

export type TConsent = {
  type: EConsentType;
  accepted: boolean;
};

export type TPatientInsurance = {
  type: string;
  title: string;
  number: string;
  iknr: string;
  status: ERetiredOrFamilyInsuredOptions;
};

export type TPatientAddress = {
  street: string;
  careOf: string;
  postCode: string;
  city: string;
  country: string;
};

export type TPatientPayload = {
  consent: TConsent[];
  dateOfBirth: string;
  email: string;
  firstName: string;
  lastName: string;
  phone: string;
  salutation: Salutation;
  title: UserTitle | null;
  preferredLocale: string; //TODO: create enum
  source: Source.PATIENT_WEB_APP;
  uniqueClientId: string;
  preferredDoctorId?: string;
  preferredPracticeId: string | null;
  captchaToken?: string;
  insurance?: TPatientInsurance;
  postAddressDTO?: TPatientAddress;
};

type TPatientResponse = {
  smsCodeDuration: number;
};

export const createPatient = async (data: TPatientPayload, locale: string): Promise<TPatientResponse> => {
  try {
    const { data: patientRes } = await api.post(`/api/v1/patient`, data, {
      baseURL: webApiBaseUrl,
      headers: {
        'Accept-Language': locale,
      },
    });

    return patientRes;
  } catch (err) {
    throw err;
  }
};
