import Countly from 'modules/countly';

import lowerEnvLogger from 'utils/loggerUtils';

type TTrackPageView = {
  href: string;
};

export type TMatomoCampaignInfo = {
  name: string;
  keywords: string | null;
} | null;

type TCampaignInfoWithTimestamp = {
  name?: string;
  keywords?: string | null;
  timestamp?: string;
};

const matomoWarning = () => {
  if (!window._paq) {
    lowerEnvLogger.warn('Matomo instance unavailable');
  }
};

const trackPageView = ({ href }: TTrackPageView) => {
  matomoWarning();
  const customUrl = href ?? window.location.href;
  window._paq?.push(['setDocumentTitle', document.title]);
  window._paq?.push(['setCustomUrl', customUrl]);
  window._paq?.push(['trackPageView']);
  Countly.TrackPageView(customUrl);
  lowerEnvLogger.info(`[Analytics] trackPageView ${href}`);
};

const trackEvent = (eventData: IMatomoEvent) => {
  matomoWarning();
  const matomoEvent = ['trackEvent', eventData.category, eventData.action, eventData.name, eventData.value];
  window._paq?.push(matomoEvent);

  // ToDo: Instead of transforming IMatomoEvent, we should pass a generic Avi Event which is transformed into the respective Motomo/Countly events
  const countlyEventData = {
    key: eventData.action ?? eventData.category,
    segmentation: {
      category: eventData.category,
      name: eventData.name,
      action: eventData.action,
      value: eventData.value,
    },
  };
  Countly.EventAdd(countlyEventData);
  lowerEnvLogger.info(`[Analytics] track event`, eventData);
};

const trackSiteSearch = (searchData: IMatomoSiteSearch) => {
  matomoWarning();
  const matomoEvent = ['trackSiteSearch', searchData.keyword, searchData.category, searchData.count];
  window._paq?.push(matomoEvent);
  const countlyEventData = {
    key: 'site_search',
    segmentation: {
      category: searchData.category,
      name: searchData.keyword,
      count: searchData.count,
    },
  };
  Countly.EventAdd(countlyEventData);
  lowerEnvLogger.info(`[Analytics] track site search`, searchData);
};

const getCampaignInfoFromMatomoCookie = (): TMatomoCampaignInfo | null => {
  try {
    const campaignInfo = document.cookie.split('; ').reduce((acc: TCampaignInfoWithTimestamp, cookie) => {
      const [key, value] = cookie.split('=');

      if (key.startsWith('_pk_ref')) {
        const parsedCookieValue = JSON.parse(decodeURIComponent(value));

        if (parsedCookieValue.length < 4) {
          return acc;
        }
        const [name, keywords, timestamp]: any = parsedCookieValue;

        if (!acc.timestamp || acc.timestamp < timestamp) {
          acc.name = name;
          acc.keywords = Boolean(keywords) ? keywords : null;
          acc.timestamp = timestamp;
        }
      }
      return acc;
    }, {});

    if (campaignInfo.name) {
      return {
        name: campaignInfo.name,
        keywords: campaignInfo.keywords || null,
      };
    }
    return null;
  } catch {
    return null;
  }
};

export { trackEvent, trackPageView, trackSiteSearch, getCampaignInfoFromMatomoCookie };
