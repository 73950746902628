import { TApptData } from 'AppContext';
import { AxiosRequestConfig, AxiosResponse } from 'axios';
import api, { webApi } from 'services/api';

import { OtpType } from 'interfaces/Otp';

import { IRFESuggestion } from '../components/SearchBox';

import { getLocale } from 'utils';
import { TMatomoCampaignInfo } from 'utils/analyticsUtils';

import { TLanguage } from 'hooks/useIntl';

export type TSlotsPayload = {
  channel: string;
  codes: string[];
  practiceId: number | null;
  staffId: number | null;
  startDate: string;
  endDate: string;
  oneTimeBookingCode: string | null;
  uniqueAppId: string;
  isFirstAppointment: boolean | null;
};

type TVideoCallLinkResponse = {
  url: string;
};

export enum AppointmentErrorReason {
  RFE_GROUP_FULLY_BOOKED = 'RFE_GROUP_FULLY_BOOKED',
  ONE_TIME_BOOKING_CODE_REQUIRED = 'ONE_TIME_BOOKING_CODE_REQUIRED',
  NO_AVAILABILITIES = 'NO_AVAILABILITIES',
  NOT_AVAILABLE_FOR_SELECTED_DOCTOR = 'NOT_AVAILABLE_FOR_SELECTED_DOCTOR',
  NOT_AVAILABLE_FOR_SELECTED_PRACTICE = 'NOT_AVAILABLE_FOR_SELECTED_PRACTICE',
  NOT_AVAILABLE_FOR_SELECTED_PRACTICE_OR_DOCTOR = 'NOT_AVAILABLE_FOR_SELECTED_PRACTICE_OR_DOCTOR',
}

export type TAvailabilities = Array<{ date: string; availabilities: string[] }>;

export type TCreateAppointmentPayload = {
  channel: string;
  codes: string[];
  notes?: string;
  practiceId: number | null;
  regionId?: number | null; // should be included only for in person appointments
  prescriptions?: string[];
  source: string;
  staffId?: number;
  startDateTime?: any;
  uniqueAppId: string;
  oneTimeBookingCode: string | null;
  captchaToken?: string;
  isFirstAppointment: boolean | null;
  isHzvPatient: boolean;
  patientSelectedHadARecentAppointment: boolean;
  campaignInfo: TMatomoCampaignInfo;
};

export const getAppointment = async (
  appointmentId?: string,
  token?: string | null,
  uniqueEmailId?: string | null,
  locale?: string
): Promise<TApptData> => {
  try {
    const lang = locale || getLocale();
    let url = `/appointment/${appointmentId}?locale=${lang}`;
    const config: AxiosRequestConfig = {};

    if (token) {
      config.headers = { Authorization: `Bearer ${token}` };
    }

    if (uniqueEmailId) {
      url += `&uniqueEmailId=${uniqueEmailId}`;
    }

    const { data } = await api.get(url, config);

    return data;
  } catch (err) {
    throw err;
  }
};

export const getAvailableSlots = async (data: TSlotsPayload): Promise<TAvailabilities> => {
  try {
    const { data: slotsInfo } = await webApi.post('/api/v1/appointment-management/availability/slot', data);

    return slotsInfo?.items;
  } catch (err) {
    throw err;
  }
};

type TGetAvailableDoctorsProps = {
  rfeCodes: string;
  regionId: string | null;
  practiceId: string | null;
  isFirstAppointment: boolean | null;
  channel?: string;
};

export const getAvailableDoctors = async ({
  rfeCodes,
  regionId,
  practiceId,
  isFirstAppointment,
  channel,
}: TGetAvailableDoctorsProps): Promise<string[]> => {
  try {
    const { data } = await api.get(`/appointment/availability/staff?${rfeCodes}`, {
      params: {
        isFirstAppointment: !!isFirstAppointment,
        regionId: regionId && channel === 'IN_PERSON' ? regionId : null,
        practiceId: practiceId && practiceId !== '0' ? practiceId : null,
        channel: channel ? channel : null,
      },
    });

    return data;
  } catch (err) {
    throw err;
  }
};

export const createAppointment = async (apptData: TCreateAppointmentPayload): Promise<any> => {
  const { campaignInfo, ...appointmentData } = apptData;

  const config = campaignInfo
    ? {
        headers: {
          'X-Campaign-Name': campaignInfo.name,
          'X-Campaign-Keywords': campaignInfo.keywords,
        },
      }
    : {};

  try {
    return await webApi.post('/api/v1/appointment', appointmentData, config);
  } catch (err: any) {
    throw new Error(JSON.stringify(err.response));
  }
};

export type TConfirmAppointmentPayload = {
  appointmentId: string;
  email: string;
  otp: string;
  locale: TLanguage;
};

export type TConfirmAppointmentResponse = {
  state: string;
  rfes: Array<IRFESuggestion>;
  staffId: string;
  practiceId: string;
  isFirstAppointment: boolean;
};

export const confirmAppointment = ({
  appointmentId,
  email,
  otp,
  locale,
}: TConfirmAppointmentPayload): Promise<AxiosResponse<TConfirmAppointmentResponse>> => {
  return webApi.put(
    `/api/v1/appointment/${appointmentId}/confirm`,
    {
      email,
      otpType: OtpType.PHONE,
      otp,
    },
    {
      headers: {
        accept: 'application/json',
        'Accept-Language': locale,
        'Content-Type': 'application/json',
      },
    }
  );
};

export const deleteAppointment = async (
  appointmentId: string,
  uniqueAppId?: string,
  uniqueEmailId?: string | null
): Promise<void> => {
  try {
    let url = `/appointment/${appointmentId}?`;

    if (uniqueAppId) {
      url += `uniqueAppId=${uniqueAppId}`;
    } else if (uniqueEmailId) {
      url += `uniqueEmailId=${uniqueEmailId}`;
    }

    await api.delete(url);
  } catch (err) {
    throw err;
  }
};

export const getVideoCallLink = async (patientCode: string): Promise<TVideoCallLinkResponse> => {
  const { data } = await api.get(`/video-call/patient-code/${patientCode}`);

  return data;
};
