import { useIntl } from 'react-intl';

import ModalBody from './ModalBody';

const EmailTaken = () => {
  const { formatMessage } = useIntl();

  const header = formatMessage({
    id: 'Modals.EmailTaken.header',
    description: 'Header for EmailTaken modal',
    defaultMessage: 'Die E-Mail Adresse ist schon vergeben',
  });

  const message = formatMessage({
    id: 'Modals.EmailTaken.message',
    description: 'Message for EmailTaken modal',
    defaultMessage:
      'Unter dieser Email Adresse ist schon ein Nutzer registriert. Bitte loggen Sie sich in der avi App auf Ihrem Smartphone ein.',
  });

  return <ModalBody header={header} message={message} />;
};

export default EmailTaken;
