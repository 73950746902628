import { useIntl } from 'react-intl';

import ModalBody from './ModalBody';

const RegistrationConfirm = (props: { onButtonClick: Function }) => {
  const { formatMessage } = useIntl();

  const header = formatMessage({
    id: 'Modals.RegistrationConfirm.header',
    description: 'Header for RegistrationConfirm modal',
    defaultMessage: 'Vielen Dank! \n Ihr Termin ist bestätigt',
  });

  const message = formatMessage({
    id: 'Modals.RegistrationConfirm.message',
    description: 'Message for RegistrationConfirm modal',
    defaultMessage: 'Ihre Terminbuchung ist von uns bestätigt worden. Wir freuen uns auf Ihren Besuch.',
  });

  const ctaButtonLabel = formatMessage({
    id: 'Modals.RegistrationConfirm.ctaButton',
    description: 'Cta for RegistrationConfirm modal',
    defaultMessage: 'Schließen',
  });

  return (
    <ModalBody
      header={header}
      message={message}
      primaryButtonLabel={ctaButtonLabel}
      onPrimaryButtonClick={() => props.onButtonClick('link')}
    />
  );
};

export default RegistrationConfirm;
