import { useIntl } from 'react-intl';

export const useSomethingWentWrongTranslations = () => {
  const { formatMessage } = useIntl();

  return {
    title: formatMessage({
      id: 'Components.SomethingWenWrong.title',
      description: 'title for SomethingWentWrong component',
      defaultMessage: 'Ups, leider ist etwas schiefgelaufen!',
    }),

    description: formatMessage({
      id: 'Components.SomethingWenWrong.description',
      description: 'description for SomethingWentWrong component',
      defaultMessage: 'Wir arbeiten an einer Lösung. Bitte laden Sie die Seite erneut oder kommen Sie in Kürze wieder.',
    }),
  };
};
