import { staticResourcesApi } from 'services/api';

import { TInsurance } from 'pages/Booking/InsuranceSelection/type';

export const fetchInsurancesApi = async (): Promise<TInsurance[]> => {
  let url = '/content/insurances/insuranceList.json';

  const response = await staticResourcesApi.get(url);
  return response.data;
};
