import { useIntl } from 'react-intl';

export const useAddressFormTranslations = () => {
  const { formatMessage } = useIntl();

  return {
    addressTitle: formatMessage({
      id: 'Components.AddressForm.addressTitle',
      description: 'title for address section',
      defaultMessage: 'Ihre Anschrift',
    }),
    streetLabel: formatMessage({
      id: 'Components.AddressForm.streetLabel',
      description: 'label for street input',
      defaultMessage: 'Straße',
    }),
    houseNumberLabel: formatMessage({
      id: 'Components.AddressForm.houseNumberLabel',
      description: 'label for house number input',
      defaultMessage: 'Hausnr.',
    }),
    zipCodeLabel: formatMessage({
      id: 'Components.AddressForm.zipCodeLabel',
      description: 'label for zip code input',
      defaultMessage: 'PLZ',
    }),
    cityLabel: formatMessage({
      id: 'Components.AddressForm.cityLabel',
      description: 'label for city input',
      defaultMessage: 'Ort',
    }),
    careOfLabel: formatMessage({
      id: 'Components.AddressForm.careOfLabel',
      description: 'label for care of input',
      defaultMessage: 'Anschriftenzusatz',
    }),
    countryLabel: formatMessage({
      id: 'Components.AddressForm.countryLabel',
      description: 'label for country input',
      defaultMessage: 'Land',
    }),
  };
};
