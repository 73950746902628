import { Step } from '../AppContext';

export const routePrefixes = {
  booking: {
    isFirstAppointment: '/is-first-appointment',
    newPatientJourney: '/new-patient-journey',
    selectRfe: '/select-rfe',
    rfeDetails: `/select-rfe/details`,
    acuteSymptom: `/acute_symptom`,
    confirmRfe: '/confirm-rfe',
    appointmentNotes: '/notes',
    regions: '/city-selection',
    appointment: '/appointment',
    appointmentConfirmation: '/confirm-appointment',
    userData: '/userdata',
    authentication: '/2fa',
    authenticationConfirm: '/2fa-confirm',
    symptomFollowUp: '/follow-up',
    acuteAppointmentWarning: '/acute-appointment-warning',
    insuranceSelection: '/insurance',
    outOfReach: '/out-of-reach',
    addOnServices: '/add-on-services',
  },
  consent: {
    start: '/start',
  },
  addToCalendar: '/add-to-calendar',
  prescription: '/prescription',
  cancelAppointment: `/cancel-appointment`,
  verifyEmail: '/verify-email',
  resetPassword: '/reset-password',
  viewMessage: `/view-message`,
  anamnesis: '/anamnesis',
  anamnesisLanding: '/view-anamnesis',
  videoCall: `/video-call`,
  signupEmailVerificationLanding: '/verify-signup',
  passwordResetEmailVerificationLanding: '/verify-password-reset',
};

export const AppRoutes = {
  // Appointment Booking
  IsFirstAppointment: () => `/booking${routePrefixes.booking.isFirstAppointment}`,
  NewPatientJourney: () => `/booking${routePrefixes.booking.newPatientJourney}`,
  SelectRfe: () => `/booking${routePrefixes.booking.selectRfe}`,
  RfeDetails: (code: string) => `/booking${routePrefixes.booking.rfeDetails}/${code}`,
  AcuteSymptom: (categoryCode?: string) => `/booking${routePrefixes.booking.acuteSymptom}/${categoryCode}`,
  SymptomFollowUp: () => `/booking${routePrefixes.booking.symptomFollowUp}`,
  AcuteAppointmentWarning: () => `/booking${routePrefixes.booking.acuteAppointmentWarning}`,
  ConfirmRfe: () => `/booking${routePrefixes.booking.confirmRfe}`,
  AppointmentNotes: () => `/booking${routePrefixes.booking.appointmentNotes}`,
  Regions: () => `/booking${routePrefixes.booking.regions}`,
  Appointment: () => `/booking${routePrefixes.booking.appointment}`,
  AppointmentConfirmation: () => `/booking${routePrefixes.booking.appointmentConfirmation}`,
  UserData: () => `/booking${routePrefixes.booking.userData}`,
  InsuranceSelection: () => `/booking${routePrefixes.booking.insuranceSelection}`,
  OutOfReach: () => `/booking${routePrefixes.booking.outOfReach}`,
  Authentication: () => `/booking${routePrefixes.booking.authentication}`,
  AuthenticationConfirm: () => `/booking${routePrefixes.booking.authenticationConfirm}`,
  AddOnServices: () => `/booking${routePrefixes.booking.addOnServices}`,

  // Consent Pages
  ConsentStart: () => `/consent${routePrefixes.consent.start}`,

  // Landing Pages
  AddToCalendar: (rfes?: string) =>
    rfes ? `${routePrefixes.addToCalendar}?rfes=${rfes}` : routePrefixes.addToCalendar,
  AddAppointmentToCalendar: (appointmentId: string) => `${AppRoutes.AddToCalendar()}/${appointmentId}`,
  CancelAppointment: (appointmentId?: string) => `${routePrefixes.cancelAppointment}/${appointmentId}`,
  VerifyEmail: () => routePrefixes.verifyEmail,
  ResetPassword: () => routePrefixes.verifyEmail,
  ViewMessage: (conversationId?: string) => `${routePrefixes.viewMessage}/${conversationId}`,
  Anamnesis: () => routePrefixes.anamnesis,
  AnamnesisLanding: () => `${routePrefixes.anamnesisLanding}/*`, // the dynamic params are not used in AnamnesisLanding screen
  VideoCall: (patientCode: string) => `${routePrefixes.videoCall}/${patientCode}`,
  SignupEmailVerificationLanding: () => routePrefixes.signupEmailVerificationLanding,
  PasswordResetEmailVerificationLanding: () => routePrefixes.passwordResetEmailVerificationLanding,
};

const BookingFlowRouteMap = {
  [Step.IS_FIRST_APPOINTMENT]: AppRoutes.IsFirstAppointment(),
  [Step.RFE]: AppRoutes.SelectRfe(),
  [Step.NOTE]: AppRoutes.AppointmentNotes(),
  [Step.APPOINTMENT]: AppRoutes.Appointment(),
  [Step.USERDATA]: AppRoutes.UserData(),
  [Step.AUTHENTICATION]: AppRoutes.Authentication(),
};

export const BookingFlowConfig = {
  getRoute: (step: Step) => BookingFlowRouteMap[step],
  getPreviousStepRoute: (step: Step) => BookingFlowRouteMap[Math.max(step - 1, 0) as Step],
};
