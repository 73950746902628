import { EmailValidator } from 'commons-validator-js';

const validator = new EmailValidator();

export const emailValidator = (email?: string) => {
  if (!email) {
    return false;
  }

  return validator.isValid(email);
};
