import React, {
  Dispatch,
  PropsWithChildren,
  SetStateAction,
  createContext,
  useContext,
  useEffect,
  useState,
} from 'react';
import { EConsentType, TConsent } from 'services/patient';

import IDoctor from './interfaces/Doctor';
import { TAddress } from './interfaces/Practice';
import { TPractice, TPracticeData } from './interfaces/Practice';
import IRFEInfo, { Channel } from 'interfaces/RFE';
import { Salutation, UserTitle } from 'pages/Booking/UserData/types';

import { loadContextFromSessionStorage, saveContext } from 'utils';

import { IntlContext } from 'hooks/useIntl';

export enum Step {
  IS_FIRST_APPOINTMENT,
  RFE,
  NOTE,
  APPOINTMENT,
  USERDATA,
  AUTHENTICATION,
}

export type TUserData = {
  email: string;
  phone: string;
  password: string;
  consent: TConsent[];
  smsNumber: string;
  title: UserTitle | null;
  salutation: Salutation | null;
  firstName: string;
  lastName: string;
  dateOfBirth: Date | null;
  address: TAddress;
  insurance: {
    type: string;
    name: string;
    number: string;
    address: TAddress;
  };
};

export type TApptData = {
  id: string;
  rfeList: IRFEInfo[];
  notes: string;
  date?: Date;
  isVideoCall: boolean;
  channel?: Channel[];
  doctor: IDoctor;
  uniqueAppId: string;
  videoCallLink?: string;
  cancellationLink?: string;
  bookedStartTime?: string;
  state?: string;
  practiceId: string;
  doctorId?: string;
  oneTimeBookingCode: string | null;
  uniqueEmailId?: string;
  isFirstAppointment: boolean | null;
  isHzvPatient: boolean | null;
  patientSelectedHadARecentAppointment: boolean | null;
  error: string | null;
  addOnServices: string[] | null;
};

export type TContextType = {
  context: {
    currentStep: number;
    smsCodeDuration: number;
    userData: TUserData;
    apptData: TApptData;
    reservedApptData: TApptData;
    locale: string;
    friendsAndFamily: boolean;
    practice: TPracticeData;
    practices: TPractice[];
    hasPreSelectedRFE: boolean;
    hasPreSelectedPractice: boolean;
  };
  setContext: Dispatch<SetStateAction<TContextType['context']>>;
  setCurrentStep: (newStep: Step) => void;
  setAppointmentError: (error: string | null) => void;
  resetContext: () => void;
  bannerVisible: boolean;
};

// Initial state
const userData: TUserData = {
  email: '',
  phone: '',
  password: '',
  consent: [
    {
      type: EConsentType.TERMS_CONDITIONS,
      accepted: true,
    },
    {
      type: EConsentType.MESSENGER,
      accepted: true,
    },
    {
      type: EConsentType.RECALLS,
      accepted: true,
    },
  ],
  smsNumber: '',
  title: null,
  salutation: null,
  firstName: '',
  lastName: '',
  dateOfBirth: null,
  address: {
    street: '',
    careOf: '',
    postCode: '',
    city: '',
  },
  insurance: {
    type: '',
    name: '',
    number: '',
    address: {
      street: '',
      careOf: '',
      postCode: '',
      city: '',
    },
  },
};

export const apptData: TApptData = {
  id: '',
  rfeList: [],
  notes: '',
  date: undefined,
  isVideoCall: false,
  doctor: {
    id: 0,
    name: '',
    specialties: [],
    languages: [],
    photo: '',
  },
  uniqueAppId: '',
  practiceId: '0',
  oneTimeBookingCode: null,
  isFirstAppointment: null,
  isHzvPatient: null,
  patientSelectedHadARecentAppointment: null,
  error: null,
  addOnServices: [],
};

const reservedApptData: TApptData = { ...apptData };

const defaultAction = () => {
  throw new Error('defaultAction');
};

export const defaultContext: TContextType = {
  context: {
    currentStep: Step.IS_FIRST_APPOINTMENT,
    smsCodeDuration: 120,
    userData,
    apptData,
    reservedApptData,
    locale: 'de',
    friendsAndFamily: false,
    practice: {
      id: '0',
      name: '',
      address: {
        postCode: '',
        street: '',
        city: '',
        district: '',
        state: '',
        country: '',
        geolocation: undefined,
      },
      photos: [],
      openingTimes: {},
      phone: '',
      doctors: [],
      mfas: [],
    },
    practices: [],
    hasPreSelectedRFE: false,
    hasPreSelectedPractice: false,
  },
  setContext: defaultAction,
  setCurrentStep: defaultAction,
  resetContext: defaultAction,
  setAppointmentError: defaultAction,
  bannerVisible: false,
};

const AppContext = createContext(defaultContext);

export const AppContextProvider: React.FC<
  PropsWithChildren<{ contextOverride?: any; uniqueAppId: string; bannerVisible: boolean }>
> = ({ children, uniqueAppId, bannerVisible, contextOverride = {} }) => {
  const { language: locale } = useContext(IntlContext);
  const initialState = {
    ...defaultContext.context,
    apptData: { ...apptData, uniqueAppId },
    ...contextOverride,
    locale,
  };

  const ctx: TContextType['context'] = loadContextFromSessionStorage() || initialState;
  const [context, setContext] = useState<TContextType['context']>(ctx);

  useEffect(() => {
    saveContext(context);
  }, [context]);

  useEffect(() => {
    // reset appointment data when isFirstAppointment changes, but keep doctor and practice selection
    /* keep also patientSelectedHadARecentAppointment and isHzvPatient since it is set on the IsFirstAppointment
       together with isFirstAppointment */
    if (ctx.apptData.isFirstAppointment !== null) {
      const { doctor, practiceId, rfeList } = ctx.apptData;
      setContext((currentContext) => ({
        ...currentContext,
        currentStep: Step.RFE,
        apptData: {
          ...initialState.apptData,
          isFirstAppointment: currentContext.apptData.isFirstAppointment,
          doctor,
          practiceId,
          patientSelectedHadARecentAppointment: currentContext.apptData.patientSelectedHadARecentAppointment,
          isHzvPatient: currentContext.apptData.isHzvPatient,
        },
      }));
      // Preserve preselected RFE's (if set from params)
      if (ctx.hasPreSelectedRFE) {
        setContext((currentContext) => ({
          ...currentContext,
          apptData: { ...currentContext.apptData, rfeList },
        }));
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [context.apptData.isFirstAppointment]);

  const handleStepChange = (newStep: Step) => {
    setContext((prev) => ({ ...prev, currentStep: newStep }));
  };

  const handleSetAppointmentError = (error: string | null) => {
    setContext((prev) => ({ ...prev, apptData: { ...prev.apptData, error } }));
  };

  const handleResetContext = () => setContext(initialState);

  const contextValue = {
    context,
    setContext,
    setCurrentStep: handleStepChange,
    resetContext: handleResetContext,
    setAppointmentError: handleSetAppointmentError,
    bannerVisible,
  };

  return <AppContext.Provider value={contextValue}>{children}</AppContext.Provider>;
};

const useAppContext = () => useContext(AppContext);
export default useAppContext;
