import { TPracticeData } from 'interfaces/Practice';

const mapPracticeData = (practice: TPracticeData, practiceId: string, staff: any) => {
  return {
    id: practiceId,
    name: practice.address.city + ' - ' + practice.address.district,
    address: {
      street: practice.address.street,
      postCode: practice.address.postCode,
      city: practice.address.city,
      district: practice.address.district,
      state: practice.address.state,
      country: practice.address.country,
      geolocation: practice.address.geolocation,
    },
    photos: practice.photos,
    openingTimes: practice.openingTimes,
    phone: practice.phone,
    doctors: staff?.filter((s: any) => s.role === 'DOCTOR'),
    mfas: staff?.filter((s: any) => s.role === 'MFA'),
    staff: practice.staff,
  };
};

export { mapPracticeData };
