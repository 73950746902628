import { useEffect, useState } from 'react';

export interface IBeforeInstallPromptEvent extends Event {
  readonly platforms: string[];
  readonly userChoice: Promise<{
    outcome: 'accepted' | 'dismissed';
    platform: string;
  }>;
  prompt(): Promise<void>;
}

export function usePromptToInstall(): [IBeforeInstallPromptEvent | null] {
  const [deferredPrompt, setDeferredPrompt] = useState<IBeforeInstallPromptEvent | null>(null);

  useEffect(() => {
    const onReady = (e: IBeforeInstallPromptEvent) => {
      // Prevent Chrome 67 and earlier from automatically showing the prompt
      e.preventDefault();
      // Stash the event so it can be triggered later.
      setDeferredPrompt(e);
      // Update UI notify the user they can add to home screen
    };

    window.addEventListener('beforeinstallprompt', onReady as any);

    return () => {
      window.removeEventListener('beforeinstallprompt', onReady as any);
    };
  }, []);

  return [deferredPrompt];
}
