import aviLogoNew from 'assets/images/avi_logo_new.svg';

import './_styles.scss';

export default function Logo() {
  return (
    <div className='logo'>
      <img data-testid='avi-medical-logo' src={aviLogoNew} alt='avi medical logo' />
    </div>
  );
}
