import { PayloadAction, createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { getRegions } from 'services/region';

import { TPractice, TPracticeData } from 'interfaces/Practice';
import IRegion, { IRegionState } from 'interfaces/Region';

export const getAllRegions = createAsyncThunk('regions/fetchAllRegions', async (locale: string) => {
  return getRegions(locale);
});

type TSelectRegionByPracticeIdProps = {
  language: string;
  practiceId: number;
  preSelectedPractice?: TPracticeData;
  callback: (selectedRegion?: IRegion) => void;
};

export const selectRegionByPracticeId = createAsyncThunk<
  Promise<void>,
  TSelectRegionByPracticeIdProps,
  { state: { regionReducer: IRegionState } }
>(
  'regions/selectRegionByPracticeId',
  async (
    { language, practiceId, preSelectedPractice, callback }: TSelectRegionByPracticeIdProps,
    { dispatch, getState }
  ): Promise<void> => {
    const state = getState();
    let { regions } = state.regionReducer;

    let selectedRegion = regions.find((region: IRegion) => {
      return region.practices.find((practice) => practice.id === practiceId);
    });
    if (!selectedRegion) {
      // @ts-ignore
      ({ payload: regions } = await dispatch(getAllRegions(language)));

      selectedRegion = regions.find((region: IRegion) => {
        return region.practices.find((practice) => practice.id === practiceId);
      });
    }

    // @NOTE-ZM: lastly we fall back to the pre-selected practiceId using the URL query /?practice=[practiceId]
    if (!selectedRegion && preSelectedPractice) {
      selectedRegion = regions.find((region: IRegion) => {
        return region.practices.find((practice) => practice.address.city === preSelectedPractice.address.city);
      });
      if (selectedRegion) {
        dispatch(
          regionSlice.actions.addPreLivePracticeDataToRegion({
            practiceData: preSelectedPractice,
            regionId: selectedRegion.id,
          })
        );
      }
    }

    return callback(selectedRegion);
  }
);

const initialState = {
  regions: [],
  error: false,
  isFetching: false,
  isFetched: false,
} as IRegionState;

const regionSlice = createSlice({
  name: 'regions',
  initialState,
  reducers: {
    addPreLivePracticeDataToRegion: (
      state,
      action: PayloadAction<{ regionId: IRegion['id']; practiceData: TPracticeData }>
    ) => {
      const regionIndex = state.regions.findIndex((region) => region.id === action.payload.regionId);
      // @NOTE-ZM: types are all over the place, eg: `Practice` and `PracticeData` both have the same data returned from API, but are defined differently for some reason
      state.regions[regionIndex].practices.push({
        ...(action.payload.practiceData as unknown as TPractice),
        practiceId: Number.parseInt(action.payload.practiceData.id),
        isPreLive: true,
      });
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getAllRegions.pending, (state) => {
      state.error = false;
      state.isFetching = true;
      state.isFetched = false;
    });
    builder.addCase(getAllRegions.fulfilled, (state, action) => {
      state.regions = action.payload;
      state.error = false;
      state.isFetched = true;
      state.isFetching = false;
    });
    builder.addCase(getAllRegions.rejected, (state) => {
      state.error = false;
      state.isFetched = true;
      state.isFetching = false;
    });
  },
});
export default regionSlice.reducer;
