import LoadingImg from 'assets/images/spinner.svg';
import { CSSProperties, MouseEvent } from 'react';

import './_styles.scss';

export enum ButtonType {
  PRIMARY = 'primary',
  SECONDARY = 'secondary',
  LINK = 'link',
}

export type TButtonProps = {
  type: ButtonType;
  label: string;
  disabled?: boolean;
  isLoading?: boolean;
  onClick?: Function;
  styles?: CSSProperties;
  icon?: any;
  'data-testid'?: LCString;
};

export default function Button({
  type,
  label,
  disabled,
  onClick,
  styles,
  icon,
  isLoading,
  'data-testid': testId = 'button',
}: TButtonProps) {
  const eventOnClick = (e: MouseEvent) => {
    if (onClick) onClick(e);
  };

  return (
    <button
      data-testid={testId}
      className={`pwa-button ${type} ${icon ? 'with-icon' : ''}`}
      disabled={disabled || isLoading}
      onClick={eventOnClick}
      style={styles}>
      {isLoading ? (
        <img
          className={type === 'primary' ? 'white-loader' : ''}
          data-testid='loading-icon'
          src={LoadingImg}
          alt='loading icon'
          width='24'
          height='24'
        />
      ) : (
        <>
          {icon && <img className='icon' src={icon} alt='icon' />}
          {label}
        </>
      )}
    </button>
  );
}
