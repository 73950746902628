import classNames from 'classnames';
import { MouseEvent } from 'react';

import { TOption } from './types';

import './_styles.scss';

export type TProps = {
  name: string;
  id: string;
  option: TOption;
  isSelected: boolean;
  onClick: (option: TOption) => void;
  renderItem?: () => React.ReactNode;
};
const RadioButton = (props: TProps) => {
  const { isSelected, name, id, option, onClick, renderItem } = props;

  function handleSelect(e: MouseEvent) {
    e.preventDefault();
    onClick(option);
  }

  const dataTestId = `radio-input-container-${name}`.toLowerCase();

  return (
    <div
      className={classNames('radio-input-container', isSelected ? 'selected' : null)}
      onClick={handleSelect}
      data-testid={dataTestId}>
      <input type='radio' id={id} name={name} defaultChecked={isSelected} />
      <div className='radio-icon-container'>
        <div className='radio-icon' />
      </div>
      {renderItem ? (
        renderItem()
      ) : (
        <label className='label-container' htmlFor={id}>
          {option.label}
        </label>
      )}
    </div>
  );
};

export default RadioButton;
