import groupBy from 'lodash/groupBy';
import shuffle from 'lodash/shuffle';
import { useEffect, useMemo, useState } from 'react';

import { EReferralOptionsType } from './types';
import { TOption } from 'components/RadioInput/types';

import ModalBody from 'components/ModalDialog/ModalBody';

import ReferralQuestionForm from './components/ReferralQuestionForm';

import { trackEvent } from 'utils/analyticsUtils';

import { useReferralQuestionModalTranslations } from './index.translations';

export type TReferralQuestionModalProps = {
  onButtonClick: (buttonType: string) => void;
  eventData: {
    isFirstAppointment: boolean;
    practiceId: string;
    rfeId: string;
    dob: string;
    salutation: string;
  };
};

const ReferralQuestionModal = ({ onButtonClick, eventData }: TReferralQuestionModalProps) => {
  const translations = useReferralQuestionModalTranslations();
  const [selectedOption, setSelectedOption] = useState<TOption | null>(null);
  const [freeTextInput, setFreeTextInput] = useState<string>('');

  // shuffle options to avoid bias on mount
  const shuffledOptions = useMemo(() => {
    const { false: standardOptions, true: otherOption } = groupBy(
      translations.options,
      ({ value }) => value === EReferralOptionsType.OTHER
    );

    return [...shuffle(standardOptions), ...otherOption];
  }, [translations.options]);

  useEffect(() => {
    trackEvent({
      category: 'View',
      action: 'webapp:modal_referralQuestionModal_show',
    });
  }, []);

  const handleOnPrimaryButtonClick = () => {
    const stringifiedEventData = Object.entries(eventData).map(([key, value]) => `${key}: ${value}`);

    if (selectedOption && selectedOption.value === EReferralOptionsType.OTHER && freeTextInput.length) {
      stringifiedEventData.unshift(`input: ${freeTextInput}`);
    }

    stringifiedEventData.unshift(`referralOption: ${selectedOption?.value}`);

    trackEvent({
      category: 'Click',
      action: 'webapp:modal_referralQuestionModal_complete',
      name: stringifiedEventData.join(', '),
    });
    // Call handler to close modal
    onButtonClick('submit');
  };

  const handleOnSecondaryButtonClick = () => {
    trackEvent({
      category: 'Click',
      action: 'webapp:modal_referralQuestionModal_close',
    });
    // Call handler to close modal
    onButtonClick('close');
  };

  const handleOnOptionSelected = (option: any) => {
    setSelectedOption(option);
  };

  const handleOnFreeTextChange = (text: string) => {
    setFreeTextInput(text);
  };

  const isContinueButtonDisabled = Boolean(
    !selectedOption || (selectedOption.value === EReferralOptionsType.OTHER && !freeTextInput.length)
  );

  return (
    <ModalBody
      header={translations.header}
      message={translations.message}
      buttonRowLayout
      body={
        <ReferralQuestionForm
          selectedOption={selectedOption}
          onFreeTextChange={handleOnFreeTextChange}
          onOptionSelected={handleOnOptionSelected}
          options={shuffledOptions}
        />
      }
      primaryButtonLabel={translations.primaryButtonLabel}
      onPrimaryButtonClick={handleOnPrimaryButtonClick}
      secondaryButtonLabel={translations.secondaryButtonLabel}
      onSecondaryButtonClick={handleOnSecondaryButtonClick}
      isPrimaryButtonDisabled={isContinueButtonDisabled}
    />
  );
};

export default ReferralQuestionModal;
