import { useIntl } from 'react-intl';

import ModalBody from './ModalBody';

interface IProps {
  onButtonClick: (btn: string) => void;
}

const VideoCallBrowserUnsupported = (props: IProps) => {
  const { formatMessage } = useIntl();
  const header = formatMessage({
    id: 'Modals.VideoCallBrowserUnsupported.header',
    description: 'Header for VideoCallBrowserUnsupported modal',
    defaultMessage: 'Browser veraltet und unterstützt Videosprechstunde nicht',
  });

  const message = formatMessage({
    id: 'Modals.VideoCallBrowserUnsupported.message',
    description: 'Message for VideoCallBrowserUnsupported modal',
    defaultMessage: 'Bitte aktualisieren Sie diesen oder probieren Sie einen anderen Browser oder Gerät aus',
  });

  const ctaButtonLabel = formatMessage({
    id: 'Modals.VideoCallBrowserUnsupported.ctaButton',
    description: 'Cta for VideoCallBrowserUnsupported modal',
    defaultMessage: 'Schließen',
  });

  return (
    <ModalBody
      header={header}
      message={message}
      primaryButtonLabel={ctaButtonLabel}
      onPrimaryButtonClick={() => props.onButtonClick('close')}
    />
  );
};

export default VideoCallBrowserUnsupported;
