import axios, { AxiosInstance } from 'axios';
import { apiBaseUrl, staticResourcesBaseUrl, webApiBaseUrl } from 'config';

const api: AxiosInstance = axios.create({
  baseURL: apiBaseUrl,
});

export const webApi: AxiosInstance = axios.create({
  baseURL: webApiBaseUrl,
});

export const staticResourcesApi: AxiosInstance = axios.create({
  baseURL: staticResourcesBaseUrl,
});

export default api;
