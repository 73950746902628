import { ReactComponent as Caret } from 'assets/images/select_handler.svg';
import { MouseEvent, useCallback, useEffect, useRef, useState } from 'react';

import useOutsideClick from 'hooks/useOutsideClick';

import './_styles.scss';

import DoctorOption from './DoctorOption';

export type TSelectOption = {
  value: string;
  text: string;
  disabled?: boolean;
  shortText?: string;
  note?: string;

  // Doctors info
  specialties?: string[];
  languages?: string[];
  photo?: string;
  position?: string;
};

export type TSelectProps = {
  options: TSelectOption[];
  title?: string;
  noBorder?: boolean;
  disabled?: boolean;
  onSelect?: Function;
  selected?: string;
  invalid?: boolean;
  label?: React.ReactNode;
  listFooter?: React.ReactNode;
  onLabelClick?: (e: any) => void;
  testId?: LCString;
};

export default function Select(props: TSelectProps) {
  const { options, title, noBorder, disabled, onSelect, selected, label, onLabelClick, testId, listFooter } = props;

  const findOption = useCallback(
    (val: string | null) => {
      const option = options.find((o) => o.value?.toString() === val);
      return option
        ? {
            option,
            index: options.indexOf(option),
          }
        : {
            option: null,
            index: 0,
          };
    },
    [options]
  );

  const [optionSelected, setOptionSelected] = useState<number>(0);
  const [open, setOpen] = useState(false);

  useEffect(() => {
    setOptionSelected(findOption(selected || '').index);
  }, [findOption, selected]);

  const ref = useRef(null);

  const handleOutsideClick = useCallback(() => {
    if (open) {
      setOpen(false);
    }
  }, [setOpen, open]);

  useOutsideClick(ref, handleOutsideClick);

  const selectItem = (e: MouseEvent, option: TSelectOption, i: number) => {
    e.stopPropagation();
    e.preventDefault();

    setOptionSelected(i);

    setOpen(false);

    if (onSelect) onSelect(option);
  };

  if (options.length === 0) {
    return null;
  }

  const classes = `pwa-dropdown ${open ? 'open' : ''} ${disabled ? 'disabled' : ''} ${noBorder ? 'noborder' : ''}`;
  const displayText = options[optionSelected]?.shortText || options[optionSelected]?.text || '';

  return (
    <div className={classes} onClick={() => setOpen(!open)} ref={ref} data-testid={testId}>
      {title && <h3 className={`title ${open ? 'open' : ''}`}>{title}</h3>}
      <span className='selected-option' data-testid='selected-option'>
        {displayText}
      </span>
      <Caret className={`caret ${open ? 'open' : ''}`} />
      <div className='options'>
        <ul>
          {label && <li onClick={onLabelClick}>{label}</li>}
          {options.map((o, i: number) => {
            if (o.languages?.length === 0 && !o.photo) {
              return (
                // @ts-ignore
                <li key={o.value} data-testid={o.value} onClick={(e) => selectItem(e, o, i)}>
                  <span className='pwa-doctor-option name'>{o.text}</span>
                  {o.note && <span className='pwa-dropdown-note'>{o.note}</span>}
                </li>
              );
            } else {
              return (
                // @ts-ignore
                <li key={o.value} data-testid={o.value} onClick={(e) => selectItem(e, o, i)}>
                  <DoctorOption
                    name={o.text}
                    position={o.position}
                    picture={o.photo}
                    specialties={o.specialties}
                    languages={o.languages}
                  />
                  {o.note && <span className='pwa-dropdown-note'>{o.note}</span>}
                </li>
              );
            }
          })}
          {listFooter && <li className='list-footer'>{listFooter}</li>}
        </ul>
      </div>
    </div>
  );
}
