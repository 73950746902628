import { useIntl } from 'react-intl';

import ModalBody from './ModalBody';

const AppointmentTaken = (props: { onButtonClick: Function }) => {
  const { formatMessage } = useIntl();

  const header = formatMessage({
    id: 'Modals.AppointmentTaken.header',
    description: 'Header for AppointmentTaken modal',
    defaultMessage: 'Termin bereits vergeben',
  });

  const message = formatMessage({
    id: 'Modals.AppointmentTaken.message',
    description: 'Message for AppointmentTaken modal',
    defaultMessage:
      'Leider ist etwas dazwischen gekommen und Ihr Termin ist nicht mehr verfügbar. Suchen Sie am besten gleich einen Neuen aus',
  });

  const ctaButtonLabel = formatMessage({
    id: 'Modals.AppointmentTaken.ctaButton',
    description: 'Cta for AppointmentTaken modal',
    defaultMessage: 'Neuen Termin auswählen',
  });

  const cancelButtonLabel = formatMessage({
    id: 'Modals.AppointmentTaken.cancelButton',
    description: 'CancelButton for AppointmentTaken modal',
    defaultMessage: 'Abbrechen',
  });

  return (
    <ModalBody
      header={header}
      message={message}
      primaryButtonLabel={ctaButtonLabel}
      onPrimaryButtonClick={() => props.onButtonClick('new_appt')}
      secondaryButtonLabel={cancelButtonLabel}
      onSecondaryButtonClick={() => props.onButtonClick('cancel')}
    />
  );
};

export default AppointmentTaken;
