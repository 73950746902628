import LoadingImg from 'assets/images/spinner.svg';

import './_styles.scss';

const PageLoader = () => {
  return (
    <div className='page-loader'>
      <img
        className='loader-icon'
        data-testid='loading-icon'
        src={LoadingImg}
        alt='loading icon'
        width='24'
        height='24'
      />
    </div>
  );
};

export default PageLoader;
