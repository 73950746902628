import { TPractice } from 'interfaces/Practice';
import IStaff from 'interfaces/Staff';

export const mapStaffData = (practiceStaff: IStaff[]) => {
  return practiceStaff.map((staff) => ({
    id: staff.id,
    name: `${staff.title ? staff.title + ' ' : ''}${staff.firstName} ${staff.lastName}`,
    position: staff.position,
    languages: staff.languages,
    picture: staff.photo,
    role: staff.role,
  }));
};

export const getAllStaff = (practices: TPractice[]) =>
  practices.reduce((allStaff: IStaff[], practice) => {
    allStaff.push(...practice.staff);
    return allStaff;
  }, []);

export const getUniqueStaff = (availableStaff: IStaff[]) => {
  let resultObject: any = [];
  const result: IStaff[] = [];

  availableStaff.forEach((staff) => {
    resultObject[staff.id] = staff;
  });

  Object.keys(resultObject).forEach((item) => result.push(resultObject[item]));

  return result;
};
