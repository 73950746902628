import { EReferralOptionsType } from '../types';
import { TOption } from 'components/RadioInput/types';

import RadioButton from 'components/RadioInput';

import { trackEvent } from 'utils/analyticsUtils';

import { useReferralQuestionModalTranslations } from '../index.translations';

import './styles.scss';

type TProps = {
  options: TOption[];
  selectedOption: TOption | null;
  onOptionSelected: (option: TOption) => void;
  onFreeTextChange: (text: string) => void;
};
const ReferralQuestionForm = ({ options, selectedOption, onFreeTextChange, onOptionSelected }: TProps) => {
  const translations = useReferralQuestionModalTranslations();

  const onRadioChange = (option: TOption) => {
    trackEvent({
      category: 'View',
      action: 'webapp:modal_ReferralQuestionForm',
    });
    onOptionSelected(option);
  };

  return (
    <div className='referral-form' data-testid='referral-form'>
      {options.map((option) => (
        <RadioButton
          key={option.value}
          name={option.value}
          id={option.value}
          option={option}
          isSelected={selectedOption ? option.value === selectedOption.value : false}
          onClick={(selectedOption) => onRadioChange(selectedOption)}
        />
      ))}

      {selectedOption?.value === EReferralOptionsType.OTHER && (
        <div className='free-text-input-container'>
          <input
            data-testid='free-text-referral-input'
            type='text'
            placeholder={translations.otherPlaceholder}
            onChange={(event) => onFreeTextChange(event.target.value)}
            autoFocus
          />
        </div>
      )}
    </div>
  );
};

export default ReferralQuestionForm;
