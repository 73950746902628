import { googleMaps } from 'config';
import { useEffect, useState } from 'react';
import usePlacesService from 'react-google-autocomplete/lib/usePlacesAutocompleteService';

import { TAddressFormData } from '../../pages/Booking/InsuranceSelection/type';

import Search from 'components/Search';

import NoAddressSearchResult from './components';

import { getNormalisedAddress } from './helpers';

import useLanguage from 'hooks/useLanguage';

import { useAutoCompleteStreetTranslations } from './index.translations';

type TAutoCompleteStreetProps = {
  query: string;
  setQuery: (query: string) => void;
  handleCompleteAddress: (address: Partial<TAddressFormData>) => void;
};

type TAddressSuggestion = {
  value: string;
  name: string;
};

const AutoCompleteStreet = ({ handleCompleteAddress, query, setQuery }: TAutoCompleteStreetProps) => {
  const { language } = useLanguage();
  const translations = useAutoCompleteStreetTranslations();
  const [showSuggestionList, setShowSuggestionList] = useState(false);

  const { placesService, placePredictions, getPlacePredictions, isPlacePredictionsLoading } = usePlacesService({
    apiKey: googleMaps.apiKey,
    debounce: 300,
    options: {
      types: ['address'],
      input: '', // this is mandatory in the type definition. set '' to satisfy typescript
    },
    language,
  });

  const handleSelectSuggestion = (suggestion: TAddressSuggestion | null) => {
    if (!suggestion) return;

    placesService?.getDetails(
      {
        placeId: suggestion.value,
        fields: ['address_component'],
      },
      (placeDetails) => {
        if (!placeDetails?.address_components) return;

        const normalisedAddress = getNormalisedAddress(placeDetails.address_components);

        handleCompleteAddress(normalisedAddress);
        setShowSuggestionList(false);
      }
    );
  };

  useEffect(() => {
    if (placePredictions.length) {
      setShowSuggestionList(true);
    }
  }, [placePredictions]);

  return (
    <Search<TAddressSuggestion>
      autoFocus={false}
      placeholder={translations.placeholder}
      showIcon={false}
      debounceTime={0}
      shouldCloseSuggestionsOnBlur
      onSearchInput={(query) => {
        getPlacePredictions({ input: query });
      }}
      suggestions={placePredictions.map((prediction) => ({
        value: prediction.place_id,
        name: prediction.description,
      }))}
      onSelectSuggestion={handleSelectSuggestion}
      NoResultComponent={NoAddressSearchResult}
      query={query}
      setQuery={setQuery}
      showSuggestionList={showSuggestionList}
      setShowSuggestionList={setShowSuggestionList}
      isFetchingSuggestions={isPlacePredictionsLoading}
      testId='auto-complete-street-input'
    />
  );
};

export default AutoCompleteStreet;
