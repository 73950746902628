import { EInsuranceFormInputTypes, ERetiredOrFamilyInsuredOptions, TInsuranceFormData } from '../../type';

import Input, { InputType } from 'components/Input/Input';
import Select from 'components/Select/Select';

import { trackEvent } from 'utils/analyticsUtils';

import { useInsuranceFormTranslations } from './InsuranceForm.translations';

import './styles.scss';

import { INSURANCE_IDENTIFICATION_NUMBER, INSURANCE_NUMBER_PATTERN } from '../../constants';

type TProps = {
  insuranceFormData: TInsuranceFormData;
  updateInsuranceFormData: (insuranceFormData: Partial<TInsuranceFormData>) => void;
};

const InsuranceForm = ({ insuranceFormData, updateInsuranceFormData }: TProps) => {
  const translations = useInsuranceFormTranslations();
  const retiredOrFamilyInsuredOptions = [
    { value: ERetiredOrFamilyInsuredOptions.NOT_RETIRED, text: translations.retiredOrFamilyInsuredOptionNo },
    {
      value: ERetiredOrFamilyInsuredOptions.FAMILY_INSURED,
      text: translations.retiredOrFamilyInsuredOptionFamilyInsured,
    },
    { value: ERetiredOrFamilyInsuredOptions.PENSIONER, text: translations.retiredOrFamilyInsuredOptionRetired },
  ];

  const handleInsuranceInputChange = (type: EInsuranceFormInputTypes, value: string) => {
    updateInsuranceFormData({ [type]: value });
  };

  const trackInputChange = (type: EInsuranceFormInputTypes, value: string) => {
    // For legal reasons we cannot track the actual input
    trackEvent({
      category: 'Input',
      action: `webapp:insuranceForm_${type}`,
      name: value ? 'value-entered' : 'value-cleared',
    });
    updateInsuranceFormData({ [type]: value });
  };

  return (
    <div className='insurance-form' data-testid='insurance-form'>
      <div className='form-line'>
        <Input
          testId='insurance-number-input'
          required
          value={insuranceFormData.insuranceNumber}
          onChange={(event) =>
            handleInsuranceInputChange(EInsuranceFormInputTypes.INSURANCE_NUMBER, event.target.value)
          }
          onBlur={() => trackInputChange(EInsuranceFormInputTypes.INSURANCE_NUMBER, insuranceFormData.insuranceNumber)}
          autoFocus
          type={InputType.TEXT}
          errormessage={translations.insuranceNumberError}
          label={translations.insuranceNumberLabel}
          maxLength={10}
          pattern={INSURANCE_NUMBER_PATTERN}
        />
      </div>
      <div className='form-line'>
        <Input
          testId='insurance-identification-number-input'
          required
          pattern={INSURANCE_IDENTIFICATION_NUMBER}
          value={insuranceFormData.insuranceIdentificationNumber}
          onChange={(event) =>
            handleInsuranceInputChange(EInsuranceFormInputTypes.INSURANCE_IDENTIFICATION_NUMBER, event.target.value)
          }
          onBlur={() =>
            trackInputChange(
              EInsuranceFormInputTypes.INSURANCE_IDENTIFICATION_NUMBER,
              insuranceFormData.insuranceIdentificationNumber
            )
          }
          type={InputType.TEXT}
          maxLength={9}
          errormessage={translations.insuranceIdentificationNumberError}
          label={translations.insuranceIdentificationNumberLabel}
        />
      </div>
      <div className='form-line family-or-retired-container'>
        <div>{translations.retiredOrFamilyInsuredLabel}</div>
        <Select
          testId='retired-or-family-insured-select'
          onSelect={(option: { text: string; value: string }) => {
            handleInsuranceInputChange(EInsuranceFormInputTypes.RETIRED_OR_FAMILY_INSURED, option.value);
            trackInputChange(EInsuranceFormInputTypes.RETIRED_OR_FAMILY_INSURED, option.value);
          }}
          selected={insuranceFormData.retiredOrFamilyInsured}
          options={retiredOrFamilyInsuredOptions}
        />
      </div>
    </div>
  );
};

export default InsuranceForm;
