import { useAutoCompleteStreetTranslations } from '../index.translations';

import './styles.scss';

const NoAddressSearchResult = () => {
  const translations = useAutoCompleteStreetTranslations();
  return (
    <div className='no-address-search-result'>
      <p data-testid='no-search-match-title' className='no-match-title'>
        {translations.noResultTitle}
      </p>
      <p data-testid='no-search-match-description' className='no-match-description'>
        {translations.noResultDescription}
      </p>
    </div>
  );
};

export default NoAddressSearchResult;
