import {
  browserProfilingIntegration,
  browserTracingIntegration,
  init,
  reactRouterV6BrowserTracingIntegration,
  replayIntegration,
} from '@sentry/react';
import { ENV, releaseVersion } from 'config';
import React from 'react';
import { createRoot } from 'react-dom/client';
import { createRoutesFromChildren, matchRoutes, useLocation, useNavigationType } from 'react-router-dom';

import App from './App';

const environmentMap = {
  DEV: 'development',
  STG: 'staging',
  PROD: 'production',
};

init({
  dsn: 'https://88dd26e6c272cf335cd51abad0714724@o4507069392551936.ingest.de.sentry.io/4507101204316240',
  integrations: [
    browserTracingIntegration(),
    browserProfilingIntegration(),
    reactRouterV6BrowserTracingIntegration({
      useEffect: React.useEffect,
      useLocation,
      useNavigationType,
      createRoutesFromChildren,
      matchRoutes,
    }),
    replayIntegration({
      maskAllText: true,
      blockAllMedia: true,
    }),
  ],
  release: releaseVersion,
  environment: environmentMap[ENV] || environmentMap.DEV,
  debug: ENV === 'DEV',
  // Performance Monitoring
  tracesSampleRate: 0.01,
  // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
  tracePropagationTargets: ['localhost', 'stg-1.avitest.info/api', 'api.avimedical.com/api'],
  normalizeDepth: 10,
  // Replay
  replaysSessionSampleRate: 0,
  replaysOnErrorSampleRate: 1.0,
  // Set profilesSampleRate to 1.0 to profile every transaction.
  // Since profilesSampleRate is relative to tracesSampleRate,
  // the final profiling rate can be computed as tracesSampleRate * profilesSampleRate
  // For example, a tracesSampleRate of 0.5 and profilesSampleRate of 0.5 would
  // results in 25% of transactions being profiled (0.5*0.5=0.25)
  profilesSampleRate: 1,
});

const container = document.getElementById('root');
const root = createRoot(container!);
root.render(<App />);

// Cleanup : Remove any serviceWorker previously installed
if ('serviceWorker' in navigator) {
  navigator.serviceWorker.ready
    .then((registration) => {
      registration.unregister();
    })
    .catch(() => {
      console.log('Issue unregistering service worker');
    });
}
