import IRFEInfo, { BookableBy, Channel, EBookingOption, IChannelConfig, RFEType } from '../interfaces/RFE';

import { IRFESuggestion } from '../components/SearchBox';

export const symptomToRFESuggestion = (acuteSymptom: IRFEInfo): IRFESuggestion => {
  return {
    code: acuteSymptom.code,
    categoryCode: acuteSymptom.categoryCode ? acuteSymptom.categoryCode : '',
    blocksOtherCategoryRFE: !!acuteSymptom.blocksOtherCategoryRFE,
    bookableBy: acuteSymptom.bookableBy ? acuteSymptom.bookableBy : BookableBy.EVERYONE,
    available: !!acuteSymptom.available,
    title: acuteSymptom.title,
    channels: acuteSymptom.channels?.length ? acuteSymptom.channels : [Channel.IN_PERSON],
    description: acuteSymptom.description || null,
    type: acuteSymptom.type,
    priceInfo: acuteSymptom.priceInfo,
  };
};

export const isAcuteRFE = (rfe: IRFEInfo) => rfe.type === RFEType.SYMPTOM;

export const hasAcuteRFESelected = (rfeList: IRFEInfo[]) => rfeList.some(isAcuteRFE);

export enum EDropDownType {
  PRACTICE = 'PRACTICE',
  DOCTOR = 'DOCTOR',
}
type TMap = {
  [key in Channel]: {
    [key in EDropDownType]?: {
      [key in EBookingOption]?: boolean;
    };
  };
};

export const getChannelConfig = (rfeChannelConfig: IChannelConfig[] | null, selectedChannel: Channel) => {
  return rfeChannelConfig?.find((config) => config.channel === selectedChannel);
};

export const channelHidesDropDown = (
  rfeChannelConfig: IChannelConfig[] | null,
  selectedChannel: Channel,
  dropDownType: EDropDownType
) => {
  const channelConfig = getChannelConfig(rfeChannelConfig, selectedChannel);
  if (!channelConfig?.bookingOption) return false;

  const map: TMap = {
    [Channel.IN_PERSON]: {
      // Only hiding the doctor selection is supported for IN_PERSON for now
      [EDropDownType.DOCTOR]: {
        [EBookingOption.REMOVE_DOCTOR_SELECTION]: true,
      },
    },
    [Channel.VIDEO_CALL]: {
      [EDropDownType.DOCTOR]: {
        [EBookingOption.REMOVE_DOCTOR_SELECTION]: true,
        [EBookingOption.REMOVE_DOCTOR_AND_PRACTICE_SELECTION]: true,
      },
      [EDropDownType.PRACTICE]: {
        [EBookingOption.REMOVE_DOCTOR_AND_PRACTICE_SELECTION]: true,
      },
    },
  };

  return map[channelConfig.channel]?.[dropDownType]?.[channelConfig.bookingOption] ?? false;
};
