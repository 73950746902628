import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { getAvailableDoctors } from 'services/appointment';

import { IPracticeState } from 'interfaces/Practice';

type TFetchAvailableStaffProps = {
  rfeCodes: string;
  isFirstAppointment: boolean | null;
  regionId: string | null;
  practiceId: string | null;
  channel?: string;
  callback: (staffIds: string[]) => void;
};

export const fetchAvailableStaff = createAsyncThunk(
  'practice/fetchAvailableStaff',
  async ({ rfeCodes, isFirstAppointment, regionId, practiceId, channel, callback }: TFetchAvailableStaffProps) => {
    const staffIds = await getAvailableDoctors({ rfeCodes, isFirstAppointment, regionId, practiceId, channel });
    callback(staffIds);

    return staffIds;
  }
);

const initialState = {
  staffIds: [],
  isFetching: false,
  isFetched: false,
  error: false,
} as IPracticeState;

const regionSlice = createSlice({
  name: 'regions',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(fetchAvailableStaff.pending, (state) => {
      state.error = false;
      state.isFetching = true;
      state.isFetched = false;
    });
    builder.addCase(fetchAvailableStaff.fulfilled, (state, action) => {
      state.staffIds = action.payload;
      state.error = false;
      state.isFetched = true;
      state.isFetching = false;
    });
    builder.addCase(fetchAvailableStaff.rejected, (state) => {
      state.error = false;
      state.isFetched = true;
      state.isFetching = false;
    });
  },
});
export default regionSlice.reducer;
