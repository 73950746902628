export enum BookableBy {
  EVERYONE = 'EVERYONE',
  RETURNING_PATIENT = 'RETURNING_PATIENT',
  MEDICAL_STAFF = 'MEDICAL_STAFF',
  NEW_PATIENT = 'NEW_PATIENT',
}

export enum Action {
  REDIRECT_TO = 'REDIRECT_TO',
  GOTO_CHILDREN = 'GOTO_CHILDREN',
}

export enum Channel {
  IN_PERSON = 'IN_PERSON',
  VIDEO_CALL = 'VIDEO_CALL',
}

export enum RFEType {
  RFE = 'RFE',
  SYMPTOM = 'SYMPTOM',
}

export enum EBookingOption {
  REMOVE_DOCTOR_SELECTION = 'REMOVE_DOCTOR_SELECTION',
  REMOVE_DOCTOR_AND_PRACTICE_SELECTION = 'REMOVE_DOCTOR_AND_PRACTICE_SELECTION',
}

export interface IChannelConfig {
  channel: Channel;
  bookingOption: EBookingOption | null;
}

export default interface IRFEInfo {
  code: string;
  categoryCode?: string;
  available?: boolean;
  bookableBy: BookableBy | null;
  channelList?: IChannelConfig[] | null;
  type: RFEType;
  action?: Action | string | null;
  target?: string | null;
  icon?: string | null;
  question?: string | null;
  title: string;
  description?: string | null;
  order?: number;
  channels?: string[];
  children?: IRFEInfo[];
  blocksOtherRFE?: boolean;
  blocksOtherCategoryRFE?: boolean;
  blocksRFEs: IRFEInfo['code'][] | null;
  disabled?: boolean;
  priceInfo: string | null;
  preferredChannel?: Channel | null;
  addOns?: string[];
}
