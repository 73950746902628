import { useIntl } from 'react-intl';

import ModalBody from './ModalBody';

const PhoneDoesNotBelongToPatientError = (props: { onButtonClick: Function }) => {
  const { formatMessage } = useIntl();

  const header = formatMessage({
    id: 'Modals.PhoneDoesNotBelongToPatientError.header',
    description: 'Header for PhoneDoesNotBelongToPatientError modal',
    defaultMessage: 'Leider gibt es ein Problem',
  });

  const message = formatMessage(
    {
      id: 'Modals.PhoneDoesNotBelongToPatientError.message',
      description: 'Message for PhoneDoesNotBelongToPatientError modal',
      defaultMessage:
        'Mit Ihrer angegebenen Telefonnummer scheint etwas nicht zu stimmen. {br}{br} Falls Sie in der Vergangenheit eine andere Telefonnummer bei uns angegeben haben, senden Sie uns bitte eine E-Mail an {link}.',
    },
    {
      br: <br />,
      link: (
        <a
          style={{ color: '#226a6d' }}
          href='mailto:info@avimedical.com?subject=Web%20App%20Handynummer%20Schwierigkeiten'>
          info@avimedical.com
        </a>
      ),
    }
  );

  const closeButtonLabel = formatMessage({
    id: 'Modals.EmailOk.ctaButton',
    description: 'Cta for EmailOk modal',
    defaultMessage: 'Schließen',
  });

  return (
    <ModalBody
      testId='phone-does-not-belong-to-patient-error'
      header={header}
      body={<p style={{ textAlign: 'left' }}>{message}</p>}
      secondaryButtonLabel={closeButtonLabel}
      onSecondaryButtonClick={() => props.onButtonClick('secondary')}
    />
  );
};

export default PhoneDoesNotBelongToPatientError;
