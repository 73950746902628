import { apiBaseUrlV2 } from 'config';
import api, { webApi } from 'services/api';

import IRFEInfo from 'interfaces/RFE';

import { getLocale } from 'utils';

const categoryResponseFormatter = (data: Array<IRFEInfo>) => {
  return data.map((it) => {
    it.categoryCode = it.code;
    return it;
  });
};

const rfeResponseFormatter = (data: Array<IRFEInfo>) => data;

export const getRFEListForCategory = async ({
  practiceId,
  code,
  locale,
  isFirstAppointment,
}: {
  practiceId: string;
  code?: string;
  locale?: string;
  isFirstAppointment: boolean | null;
}): Promise<IRFEInfo[]> => {
  const lang = locale || getLocale();
  const options = {
    params: { isFirstAppointment: !!isFirstAppointment },
  };
  if (!code) {
    // root category
    return api
      .get(`/rfe-category?locale=${lang}&practiceId=${practiceId !== '0' ? practiceId : null}`, options)
      .then((resp) => categoryResponseFormatter(resp.data))
      .catch((err) => {
        throw err;
      });
  } else {
    // node category
    return api
      .get(`/rfe-category/${code}/rfe?locale=${lang}&practiceId=${practiceId !== '0' ? practiceId : null}`, options)
      .then((resp) => rfeResponseFormatter(resp.data))
      .catch((err) => {
        throw err;
      });
  }
};

export const searchAcuteSymptoms = async ({
  query,
  category,
  locale,
  isFirstAppointment,
}: {
  query: string;
  category?: string;
  locale?: string;
  isFirstAppointment: boolean | null;
}): Promise<IRFEInfo[]> => {
  const lang = locale || getLocale();
  return api
    .get(`/rfe-category/${category}/rfe`, {
      params: {
        locale: lang,
        query,
        isFirstAppointment: !!isFirstAppointment,
      },
    })
    .then((resp) => resp.data as IRFEInfo[])
    .catch((err) => {
      throw err;
    });
};

export const getAvailableRFEByCodes = async ({
  practiceId,
  codes,
  locale,
  isFirstAppointment,
}: {
  practiceId: string;
  codes: string[];
  locale?: string;
  isFirstAppointment: boolean | null;
}): Promise<Array<IRFEInfo>> => {
  try {
    const lang = locale || getLocale();

    const { data } = await webApi.get('api/v1/appointment-management/availability/rfe', {
      params: {
        isFirstAppointment: !!isFirstAppointment,
        practiceId: practiceId !== '0' ? practiceId : null,
        codes: codes,
      },
      paramsSerializer: {
        indexes: null, // by default: false
      },
      headers: {
        'Accept-Language': lang,
      },
    });

    return rfeResponseFormatter(data.items);
  } catch (err) {
    throw err;
  }
};

export const createAddOnServiceInterest = async ({
  addOnServiceRfeCodes,
  patientEmail,
  captchaToken,
}: {
  addOnServiceRfeCodes: string[];
  patientEmail: string;
  captchaToken?: string;
}): Promise<any> => {
  const payload = {
    email: patientEmail,
    captchaToken: captchaToken,
    entries: addOnServiceRfeCodes.map((code) => ({ rfeCode: code, status: 'INTERESTED' })),
  };

  try {
    return await webApi.post('/api/v1/add-on-service-interest', payload);
  } catch (err: any) {
    throw new Error(JSON.stringify(err.response));
  }
};

// This is V2 and is now deprecated
export const getRFEListByCodes = async ({
  practiceId,
  codes,
  locale,
  isFirstAppointment,
}: {
  practiceId: string;
  codes: string[];
  locale?: string;
  isFirstAppointment: boolean | null;
}): Promise<Array<IRFEInfo>> => {
  try {
    const lang = locale || getLocale();
    const { data } = await api.get('/rfe', {
      baseURL: apiBaseUrlV2,
      params: {
        isFirstAppointment: !!isFirstAppointment,
        practiceId: practiceId !== '0' ? practiceId : null,
        rfeCodes: codes,
        locale: lang,
      },
    });

    return rfeResponseFormatter(data);
  } catch (err) {
    throw err;
  }
};
