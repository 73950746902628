import { useContext } from 'react';

import useAppContext from '../AppContext';
import { IntlContext } from './useIntl';

const useLanguage = () => {
  const { language, changeLanguage } = useContext(IntlContext);
  const { setContext, context } = useAppContext();
  const LANGUAGES = ['de', 'en'];

  const setLanguage = (lang: string) => {
    let locale = lang || context.locale;
    if (!locale || !LANGUAGES.includes(locale)) {
      locale = 'de';
    }
    if (language !== locale) {
      setContext((prev) => ({ ...prev, locale }));
      changeLanguage(locale);
    }
  };

  return { setLanguage, language };
};

export default useLanguage;
