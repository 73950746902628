import { useIntl } from 'react-intl';

import ModalBody from './ModalBody';

const EmailConfirm = (props: { email?: string }) => {
  const { formatMessage } = useIntl();

  const header = formatMessage({
    id: 'Modals.EmailConfirm.header',
    description: 'Header for EmailConfirm modal',
    defaultMessage: 'Bitte bestätigen Sie Ihre \nE-Mail-Adresse',
  });

  const message = formatMessage(
    {
      id: 'Modals.EmailConfirm.message',
      description: 'Message for EmailConfirm modal',
      defaultMessage:
        'Wir haben Ihnen einen Aktivierungslink an Ihre E-Mail-Adresse {email} zugesendet. Bitte öffnen Sie den Link um diese zu bestätigen.',
    },
    {
      email: <strong>{props.email}</strong>,
    }
  );

  return <ModalBody header={header} message={message} />;
};

export default EmailConfirm;
