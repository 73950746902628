import classNames from 'classnames';
import debounce from 'lodash/debounce';
import { ChangeEventHandler, ComponentType, useCallback } from 'react';

import './styles.scss';

import SuggestionList from '../SuggestionList';

interface ISearchProps<T> {
  placeholder?: string;
  onSearchInput: (query: string) => void;
  maxLength?: number;
  suggestions: T[];
  onSelectSuggestion: (suggestion: T | null) => void;
  NoResultComponent: ComponentType;
  debounceTime?: number;
  autoFocus?: boolean;
  SuggestionComponent?: ComponentType<{ suggestion: T }>;
  query: string;
  setQuery: (query: string) => void;
  showSuggestionList: boolean;
  setShowSuggestionList: (show: boolean) => void;
  showIcon?: boolean;
  shouldCloseSuggestionsOnBlur?: boolean;
  isFetchingSuggestions: boolean;
  testId?: Lowercase<string>;
}

function Search<T extends { name: string }>({
  suggestions,
  onSelectSuggestion,
  NoResultComponent,
  placeholder,
  onSearchInput,
  showIcon = true,
  maxLength = 70,
  autoFocus = true,
  debounceTime = 200,
  SuggestionComponent,
  query,
  setQuery,
  showSuggestionList,
  setShowSuggestionList,
  shouldCloseSuggestionsOnBlur,
  isFetchingSuggestions,
  testId = 'search-input',
}: Readonly<ISearchProps<T>>) {
  const containerTestId = `${testId}-container` as Lowercase<string>;

  const handleOnInputChange: ChangeEventHandler<HTMLInputElement> = (event) => {
    const query = event.target.value;

    onSelectSuggestion(null);
    setQuery(query);

    debounceSearch(query);
  };

  const onInputFocus = () => {
    setShowSuggestionList(true);
  };

  const onInputBlur = () => {
    if (shouldCloseSuggestionsOnBlur) {
      setTimeout(() => {
        setShowSuggestionList(false);
      }, 150);
    }
  };

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const debounceSearch = useCallback(
    debounce((searchQuery: string) => {
      onSearchInput(searchQuery);
    }, debounceTime),
    []
  );

  const handleSelectSuggestion = (suggestion: T) => {
    onSelectSuggestion(suggestion);
    setShowSuggestionList(false);
  };

  return (
    <div className='search-container' data-testid={containerTestId}>
      <input
        data-testid={testId}
        className={`${showIcon ? 'with-icon' : ''}`}
        type='text'
        onChange={handleOnInputChange}
        placeholder={placeholder}
        maxLength={maxLength}
        autoFocus={autoFocus}
        onFocus={onInputFocus}
        onBlur={onInputBlur}
        value={query}
      />

      <div className={classNames('search-list-container', showSuggestionList ? 'show' : null)}>
        <SuggestionList<T>
          suggestions={suggestions}
          query={query}
          isLoading={isFetchingSuggestions}
          onSelectSuggestion={handleSelectSuggestion}
          Suggestion={SuggestionComponent}
          NoResultComponent={NoResultComponent}
        />
      </div>
    </div>
  );
}

export default Search;
