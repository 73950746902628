import { ENV, countly } from 'config';
import { ICountlyEvent, default as _countly } from 'countly-sdk-web';

import { IRemoteConfig } from './types';

import lowerEnvLogger from 'utils/loggerUtils';

import Package from '../../../package.json';

class CountlyModule {
  private countlySdk: typeof _countly;
  public isInitialized = false;
  public isTrackingPerformance = false;
  private isProduction = ENV === 'PROD';

  constructor() {
    // We require some additional plugins for the perf monitoring, however these require a global Countly instance (scoped to window).
    const windowObj = window as any;
    windowObj.Countly = _countly;
    this.countlySdk = windowObj.Countly;

    // https://support.count.ly/hc/en-us/articles/360037441932-Web-analytics-JavaScript-#synchronous-implementation
    // Since the global Countly object is only set after init, we cannot import the below plugins, but instead need to require
    // This should not have a dramatic negative impact since these are local files
    require('countly-sdk-web/plugin/boomerang/countly_boomerang');
    require('countly-sdk-web/plugin/boomerang/boomerang.min');
  }

  public Init(): void {
    // Protect against double initialization
    if (this.isInitialized) return;

    this.countlySdk.init({
      app_key: countly.appKey,
      url: countly.instanceUrl,
      debug: !this.isProduction,
      metrics: {
        _app_version: Package.version,
        _browser: navigator.userAgent,
      },
      remote_config: true,
      require_consent: this.isProduction,
      use_explicit_rc_api: false,
    });
    this.isInitialized = true;

    this.countlySdk.group_features({ activity: ['sessions', 'events', 'views', 'crashes'] });

    this.countlySdk.add_consent(['remote-config', 'apm', 'sessions']);
    lowerEnvLogger.info('[Countly] Initialized');
  }

  public AutomaticPerfMonitoring(): void {
    if (this.isInitialized) {
      this.countlySdk.track_performance({
        //page load timing
        RT: {},
        //required for automated networking traces
        instrument_xhr: true,
        captureXhrRequestResponse: true,
        AutoXHR: {
          alwaysSendXhr: true,
          monitorFetch: true,
          captureXhrRequestResponse: true,
        },
        //required for screen freeze traces
        Continuity: {
          enabled: true,
          monitorLongTasks: true,
          monitorPageBusy: true,
          monitorFrameRate: true,
          monitorInteractions: true,
          afterOnload: true,
        },
      });
      this.isTrackingPerformance = true;
      lowerEnvLogger.info('[Countly] AutomaticPerfMonitoring enabled');
    } else {
      lowerEnvLogger.error('[Countly] Not yet initialized.');
    }
  }

  public SessionStart(): void {
    if (this.isInitialized) {
      this.countlySdk.begin_session();
      lowerEnvLogger.info('[Countly] SessionStart');
    } else {
      lowerEnvLogger.error('[Countly] Not yet initialized.');
    }
  }

  public SessionEnd(): void {
    if (this.isInitialized) {
      this.countlySdk.end_session();
      lowerEnvLogger.info('[Countly] SessionEnd');
    } else {
      lowerEnvLogger.error('[Countly] Not yet initialized.');
    }
  }

  public GetRemoteConfig = (callback: (config: IRemoteConfig | null) => void) => {
    if (!this.countlySdk.fetch_remote_config || !this.isInitialized) {
      lowerEnvLogger.error('[Countly] Not yet initialized.');
    }

    this.countlySdk.fetch_remote_config((err: any, remoteConfig: IRemoteConfig) => {
      // err` is never truthy unless there's JSON parsing error,
      // network failures won't be captured by Countly :)
      // https://github.com/Countly/countly-sdk-web/blob/bc809e8edac81f7d8348d5e5520ac29f41f8c4d9/lib/countly.js#L1622
      if (err) {
        let errorOutput = '';
        if (err instanceof Error) {
          errorOutput = err.message;
        } else {
          errorOutput = JSON.stringify(err);
        }
        lowerEnvLogger.error('[Countly] Failed to fetch remote config', errorOutput);

        callback(null);
      } else {
        lowerEnvLogger.info('[Countly] RemoteConfig fetched', remoteConfig);

        callback(remoteConfig);
      }
    });
  };

  // Use EventStart() and EventEnd() for timed events
  // eg: timing the booking flow
  public EventStart(event: string): void {
    if (this.isInitialized) {
      lowerEnvLogger.info(`[Countly] EventStart ${event}`);
      this.countlySdk.start_event(event);
    } else {
      lowerEnvLogger.error('[Countly] Not yet initialized.');
    }
  }

  public EventEnd(event: string): void {
    if (this.isInitialized) {
      lowerEnvLogger.info(`[Countly] EventEnd ${event}`);
      this.countlySdk.end_event(event);
    } else {
      lowerEnvLogger.error('[Countly] Not yet initialized.');
    }
  }

  // Use EventAdd() for single time events
  // eg: clicking on an element
  public EventAdd(eventData: ICountlyEvent): void {
    if (this.isInitialized) {
      this.countlySdk.add_event(eventData);
    } else {
      lowerEnvLogger.error('[Countly] Not yet initialized.');
    }
  }

  public giveConsent(consent: string): void {
    if (this.isInitialized) {
      this.countlySdk.add_consent(consent);
      lowerEnvLogger.info('[Countly] Consent given', consent);
    } else {
      lowerEnvLogger.error('[Countly] Not yet initialized.');
    }
  }

  public TrackPageView(view: string): void {
    if (this.isInitialized) {
      this.countlySdk.track_pageview(view);
    } else {
      lowerEnvLogger.error('[Countly] Not yet initialized.');
    }
  }
}

const Countly = new CountlyModule();
export default Countly;
export const TestCountlyModule = CountlyModule;
