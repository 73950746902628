import { RefObject, useEffect } from 'react';

const useOutsideClick = (ref: RefObject<HTMLElement>, callback: Function) => {
  const handleClick = (e: CustomEvent) => {
    if (ref.current && !ref.current.contains(e.target as Node)) {
      callback();
    }
  };

  useEffect(() => {
    document.addEventListener('click', handleClick as EventListener);

    return () => {
      document.removeEventListener('click', handleClick as EventListener);
    };
  });
};

export default useOutsideClick;
