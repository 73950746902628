import { MouseEvent } from 'react';
import { useIntl } from 'react-intl';

import Button, { ButtonType } from './Button';

export default function ContinueButton(props: {
  onClick: (e: MouseEvent) => void;
  disabled?: boolean;
  label?: string;
  isLoading?: boolean;
  testid?: LCString;
}) {
  const { formatMessage } = useIntl();

  const defaultLabel = formatMessage({
    id: 'Components.Button.ContinueButton.label',
    description: 'Label for continue button',
    defaultMessage: 'Weiter',
  });

  const eventOnClick = (e: MouseEvent) => {
    if (props.onClick) props.onClick(e);
  };

  return (
    <Button
      type={ButtonType.PRIMARY}
      label={props.label || defaultLabel}
      onClick={eventOnClick}
      disabled={props.disabled}
      isLoading={props.isLoading}
      data-testid={props.testid || 'button'}
    />
  );
}
