import { TInsurance } from '../../type';

import './styles.scss';

const InsuranceSuggestion = ({ suggestion }: { suggestion: TInsurance }) => {
  return (
    <div className='suggestion-container'>
      <div className='suggestion-name'>{suggestion.name}</div>
      <div className='suggestion-type'>{suggestion.type}</div>
    </div>
  );
};

export default InsuranceSuggestion;
