import { useIntl } from 'react-intl';

import ModalBody from './ModalBody';

const InvalidCode = (props: { onButtonClick: Function }) => {
  const { formatMessage } = useIntl();

  const message = formatMessage({
    id: 'Modals.InvalidCode.message',
    description: 'Error Message if the verification code is wrong or expired.',
    defaultMessage: 'Der Code ist leider ungültig. Bitte versuche es erneut.',
  });

  const ctaButtonLabel = formatMessage({
    id: 'Modals.InvalidCode.ctaButton',
    description: 'Cta for InvalidCode modal',
    defaultMessage: 'Erneut versuchen',
  });

  return (
    <ModalBody
      message={message}
      primaryButtonLabel={ctaButtonLabel}
      onPrimaryButtonClick={() => props.onButtonClick('primary')}
    />
  );
};

export default InvalidCode;
