import { useIntl } from 'react-intl';

import ModalBody from './ModalBody';
import styles from './modal.module.scss';

export enum InvalidRFEBookingCodeModalType {
  BOOKING_CODE_REQUIRED,
  BOOKING_CODE_INVALID,
}

const InvalidRFEBookingCode = (props: {
  onButtonClick: Function;
  cancellationModalType: InvalidRFEBookingCodeModalType;
}) => {
  const { formatMessage } = useIntl();
  let header, message, ctaButtonLabel;
  const modalValues = {
    br: (
      <>
        <br />
        <br />
      </>
    ),
    a: (chunks: React.ReactNode) => (
      <a
        href='https://www.avimedical.com/leistungen/impfung-gegen-covid'
        className={styles.link}
        title={chunks as string}
        rel='noreferrer'>
        {chunks}
      </a>
    ),
  };

  if (props.cancellationModalType === InvalidRFEBookingCodeModalType.BOOKING_CODE_INVALID) {
    header = formatMessage({
      id: 'Modals.BookingCodeInvalid.header',
      description: 'Ungültiger Einladungs-Link',
    });

    message = formatMessage(
      {
        id: 'Modals.BookingCodeInvalid.message',
        description:
          'Corona Impfungen könne nur mit einer gültigen, persönlichen Einladung gebucht werden.Noch keine E-mail mit persönlichem Einladungs-Link erhalten? Dann setze dich jetzt auf unsere Corona <a>Impfwarteliste</a>.',
      },
      modalValues
    );

    ctaButtonLabel = formatMessage({
      id: 'Modals.BookingCodeInvalid.ctaButton',
      description: 'Cta for BookingCodeInvalid modal',
      defaultMessage: 'Zurück',
    });
  } else {
    header = formatMessage({
      id: 'Modals.BookingCodeRequired.header',
      description: 'Header for BookingCodeRequired modal',
      defaultMessage: 'Einladung benötigt',
    });

    message = formatMessage(
      {
        id: 'Modals.BookingCodeRequired.message',
        description: 'Message for BookingCodeRequired modal',
        defaultMessage:
          'Corona Impfungen könne nur mit einer gültigen, persönlichen Einladung gebucht werden.{br}Um die E-mail mit persönlichem Einladungs-Link zu erhalten, setze dich jetzt auf unsere Corona <a>Impfwarteliste</a>.',
      },
      modalValues
    );

    ctaButtonLabel = formatMessage({
      id: 'Modals.BookingCodeRequired.ctaButton',
      description: 'Cta for BookingCodeRequired modal',
      defaultMessage: 'Zurück',
    });
  }

  return (
    <ModalBody
      header={header}
      message={message}
      primaryButtonLabel={ctaButtonLabel}
      onPrimaryButtonClick={() => props.onButtonClick('close')}
    />
  );
};

export default InvalidRFEBookingCode;
