import SearchNotFoundImage from 'assets/images/NotFoundIllustrationWithRoundedBackground.png';
import isEmpty from 'lodash/isEmpty';
import { ComponentType } from 'react';

import { useSuggestionListTranslations } from './index.translations';

import './styles.scss';

interface ISuggestionListProps<T> {
  suggestions: T[];
  query: string;
  onSelectSuggestion: (suggestion: T) => void;
  NoResultComponent: ComponentType;
  Suggestion?: ComponentType<{ suggestion: T }>;
  isLoading: boolean;
}
function SuggestionList<T extends { name: string }>({
  Suggestion,
  suggestions,
  query,
  NoResultComponent,
  onSelectSuggestion,
  isLoading,
}: Readonly<ISuggestionListProps<T>>) {
  const translations = useSuggestionListTranslations();
  return (
    <div className='suggestions-container'>
      {suggestions.length ? (
        <>
          {!query.length ? (
            <p className='suggestion-frequently-search-label'>{translations.frequentlySearchLabel}</p>
          ) : null}
          <div className='suggestions' data-testid='suggestions-results'>
            {suggestions.map((suggestion) => (
              <div className='suggestion' key={suggestion.name} onClick={() => onSelectSuggestion(suggestion)}>
                {Suggestion ? <Suggestion suggestion={suggestion} /> : suggestion.name}
              </div>
            ))}
          </div>
        </>
      ) : null}

      {!isEmpty(query) && isEmpty(suggestions) && !isLoading && (
        <div className='no-search-result-container'>
          <img src={SearchNotFoundImage} width={100} alt='No search results' />
          <div>
            <NoResultComponent />
          </div>
        </div>
      )}
    </div>
  );
}

export default SuggestionList;
