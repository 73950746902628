export enum EReferralOptionsType {
  ONLINE_SEARCH = 'onlineSearch',
  GOOGLE_MAPS = 'googleMaps',
  INSTAGRAM = 'instagram',
  FACEBOOK = 'facebook',
  EVENT = 'event',
  OUTDOOR_ADS = 'outdoorAds',
  MAILINGS = 'mailings',
  FLYERS = 'flyers',
  PRINT_ADS = 'printAds',
  FRIENDS_AND_FAMILY = 'friendsAndFamily',
  MEDICAL_REFERRAL = 'medicalReferral',
  JAMEDA = 'jameda',
  OTHER = 'other',
}
