import { useIntl } from 'react-intl';

import ModalBody from './ModalBody';

const GenericError = (props: { onButtonClick: Function }) => {
  const { formatMessage } = useIntl();

  const header = formatMessage({
    id: 'Modals.GenericError.header',
    description: 'Header for GenericError modal',
    defaultMessage: 'Leider ist gerade etwas schief gelaufen',
  });

  const message = formatMessage({
    id: 'Modals.GenericError.message',
    description: 'Message for GenericError modal',
    defaultMessage: 'Es ist leider ein Fehler aufgetreten. Bitte versuchen Sie es in ein paar Minuten erneut.',
  });

  const ctaButtonLabel = formatMessage({
    id: 'Modals.EmailOk.ctaButton',
    description: 'Cta for EmailOk modal',
    defaultMessage: 'Schließen',
  });

  return (
    <ModalBody
      testId='generic-error'
      header={header}
      message={message}
      primaryButtonLabel={ctaButtonLabel}
      onPrimaryButtonClick={() => props.onButtonClick('primary')}
    />
  );
};

export default GenericError;
