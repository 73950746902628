import useAppContext, { defaultContext } from 'AppContext';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { matchPath } from 'react-router';
import { useLocation } from 'react-router-dom';
import { getAllRegions } from 'redux/Slices/regions';
import { TRootState, useAppDispatch } from 'redux/Store/store';
import { getPractice, getPractices } from 'services/practice';
import { getRFEListByCodes } from 'services/rfe';

import IRegion from '../../interfaces/Region';
import { TPractice, TPracticeData } from 'interfaces/Practice';
import IRFEInfo from 'interfaces/RFE';

import PageLoader from 'components/PageLoader';

import { trackEvent, trackPageView } from 'utils/analyticsUtils';
import { routePrefixes } from 'utils/routeUtils';

import { isValidRegion } from './helpers';

import useLanguage from 'hooks/useLanguage';
import { useQueryString } from 'hooks/useQueryString';

import { DEFAULT_EMPTY_DOCTOR, DEFAULT_PRACTICE_ID } from '../../constants';
import { mapPracticeData, mapPracticeStaff } from './mappers';

// TODO: this should actually be null/undefined but
// we are getting the list of RFE's based on this id
// if the id is null/undefined the RFE's will not be available
// const DEFAULT_PRACTICE_ID = '0';

// Dev Note :
// This component handles the app initialization (ie : fetch data & handle correct redirect )
const AppWrapper = ({ children }: any) => {
  const { context } = useAppContext();
  const location = useLocation();

  const { setContext } = useAppContext();
  const { language, setLanguage } = useLanguage();
  const queryString = useQueryString();
  const dispatch = useAppDispatch();

  const [isReady, setIsReady] = useState(false);

  const practiceId: string = queryString.get('practice') || context.apptData.practiceId;

  const locale = (queryString.get('lang') ?? queryString.get('locale')) as string;
  const doctorId = queryString.get('doctor') ?? context.apptData.doctor.id;
  const faf = queryString.get('faf');
  const rfeCodes = queryString.get('rfe');
  const oneTimeBookingCode = queryString.get('oneTimeBookingCode');
  const isFirstAppointmentQueryParam = queryString.get('isFirstAppointment');
  const isFirstAppointment = isFirstAppointmentQueryParam
    ? isFirstAppointmentQueryParam === 'true'
    : context.apptData.isFirstAppointment;
  const regionIdQueryParam = queryString.get('region') ? Number(queryString.get('region')) : undefined;
  const { regions: reduxRegions, isFetched: isRegionsFetched } = useSelector(
    (state: TRootState) => state.regionReducer
  );

  // analytics
  useEffect(() => {
    trackPageView({ href: window.location.href });
    trackEvent({ category: 'View', action: 'Practice_Selected', name: `PracticeID_${practiceId}` });

    setContext((prev) => ({
      ...prev,
      friendsAndFamily: faf === 'true',
      apptData: { ...prev.apptData, oneTimeBookingCode },
    }));
    setLanguage(locale);

    // Dev Note:
    // Previously, booking related routes were not nested under /booking/
    // In case an old link is open, we manually redirect to the new /booking/ url
    /* In react router v6 the path matching is more exact. Eg, we cannot use a single pattern to match both /select-rfe and /select-rfe/details/:category.
       Therefore for this use case, use startsWith */
    if (Object.values(routePrefixes.booking).some((route) => location.pathname.startsWith(route))) {
      window.location.replace(`/booking${location.pathname}`);
      return;
    }

    // my head hurts :)
    if (isRegionsFetched) {
      getData(reduxRegions);
    } else {
      dispatch(getAllRegions(language))
        .unwrap()
        .then((regionsResponse) => getData(regionsResponse));
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const shouldHideInitialLoader = () => {
    // Add all conditions here for when we want to hide the loading modal
    return !!matchPath(routePrefixes.anamnesis, location.pathname);
  };

  const getData = async (regions: IRegion[]) => {
    let practiceData;

    if (practiceId !== DEFAULT_PRACTICE_ID) {
      practiceData = await getPractice(practiceId, language);
      const region = regions.find((region) => region.practices.find((practice) => practice.id === +practiceId));
      if (region) {
        localStorage.setItem('preferred-region', JSON.stringify({ id: region.id, name: region.name }));
      }
    } else {
      practiceData = defaultContext.context.practice;
    }

    // if the regions are fetched and
    // if we have a region query param present
    // we make sure that the region is existing and valid
    // if not we ignore the queryParam
    if (regionIdQueryParam) {
      const isValid = isValidRegion(regions, regionIdQueryParam);
      if (isValid) {
        localStorage.setItem(
          'preferred-region',
          JSON.stringify({
            id: regionIdQueryParam,
          })
        );
      }
    }

    let doctor = DEFAULT_EMPTY_DOCTOR;
    let rfeList: IRFEInfo[] = [];

    const staff = mapPracticeStaff(practiceData);

    const practice: TPracticeData = mapPracticeData(practiceData, practiceId, staff);

    const existedDoctor = staff?.find((doc: any) => doc.id === Number(doctorId));

    if (doctorId && existedDoctor) {
      doctor = {
        id: Number(doctorId),
        name: existedDoctor?.name,
        languages: existedDoctor?.languages,
        specialties: existedDoctor?.specialties,
      };
    }

    if (queryString.get('practice')) {
      setContext((prev) => ({
        ...prev,
        hasPreSelectedPractice: true,
      }));
    }

    // Why do we need this weird "null" check...?
    // There are links such as https://app.sha.stg.avimedical.info/?practice=1&rfe=null
    // Which would be interpreted as rfe === "null"
    if (rfeCodes && rfeCodes !== 'null') {
      const rfes = await getRFEListByCodes({
        practiceId,
        codes: rfeCodes.split(','),
        locale: language,
        isFirstAppointment,
      });

      if (rfes.length) {
        rfeList = rfeList.concat(rfes);
      }

      // DEV Note: When we have a valid rfe in the query params,
      // We will skip the `rfe selection` screen and the `add a note` screen
      // But first need to redirect to the `is first appointment` screen
      if (rfeList.length) {
        setContext((prev) => ({
          ...prev,
          hasPreSelectedRFE: true,
        }));
      }
    } else {
      rfeList = context.apptData.rfeList;
    }

    let practices: TPractice[] = [];

    try {
      const res = await getPractices();

      if (res && res.length) {
        practices = res;
        // @NOTE-ZM: in case the pre-selected practice is pre-live, add it to the list. We can tell that a practice is pre-live if it doesn't exist on the GET /practices response
        if (practiceId !== DEFAULT_PRACTICE_ID && !practices.some(({ id }) => String(id) === String(practice.id))) {
          // @NOTE-ZM: types are all over the place, eg: `Practice` and `PracticeData` both have the same data returned from API, but are defined differently for some reason
          practices.push({
            ...(practice as unknown as TPractice),
            practiceId: Number.parseInt(practice.id),
            isPreLive: true,
          });
          practice.isPreLive = true;
        }
      }
    } catch (error) {
      practices = [];
    }

    setContext((prev) => ({
      ...prev,
      practice,
      apptData: { ...prev.apptData, rfeList, doctor, practiceId: practice.id, isFirstAppointment },
      practices,
    }));

    setIsReady(true);
  };

  // the routes will only be available after the app state got bootstrapped
  if (isReady) {
    return <div className='wrapper'>{children}</div>;
  }

  if (shouldHideInitialLoader()) {
    return null;
  }

  return <PageLoader />;
};

export default AppWrapper;
