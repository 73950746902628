import { useIntl } from 'react-intl';

export const useNavbarTranslations = () => {
  const { formatMessage } = useIntl();

  return {
    homeLabel: formatMessage({
      id: 'Pages.Home.homeLabel',
      description: 'Home title in navbar',
      defaultMessage: 'Home',
    }),
  };
};
