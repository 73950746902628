import { EAddressFormInputTypes, TAddressFormData } from '../../type';

import AutoCompleteStreet from 'components/AutoCompleteStreet';
import Input, { InputType } from 'components/Input/Input';

import { trackEvent } from 'utils/analyticsUtils';

import { useAddressFormTranslations } from './AddressForm.translations';

import './styles.scss';

type TProps = {
  addressFormData: TAddressFormData;
  updateAddressFormData: (addressFormData: Partial<TAddressFormData>) => void;
};

const AddressForm = ({ addressFormData, updateAddressFormData }: TProps) => {
  const translations = useAddressFormTranslations();

  const handleAddressInputChange = (type: EAddressFormInputTypes, value: string) => {
    updateAddressFormData({ [type]: value });
  };

  const trackInputChange = (type: EAddressFormInputTypes, value: string) => {
    // For legal reasons we cannot track the actual input
    trackEvent({
      category: 'Input',
      action: `webapp:insuranceAddressForm_${type}`,
      name: value ? 'value-entered' : 'value-cleared',
    });
  };

  return (
    <div className='address-form' data-testid='address-form'>
      <h4 className='title-4'>{translations.addressTitle}</h4>

      <div className='form-line'>
        <AutoCompleteStreet
          query={addressFormData.street}
          setQuery={(value) => handleAddressInputChange(EAddressFormInputTypes.STREET, value)}
          handleCompleteAddress={(address: Partial<TAddressFormData>) => {
            updateAddressFormData(address);
          }}
        />
        <div className='small-input'>
          <Input
            testId='house-number-input'
            required
            value={addressFormData.houseNumber}
            onChange={(event) => handleAddressInputChange(EAddressFormInputTypes.HOUSE_NUMBER, event.target.value)}
            autoComplete='address-line2'
            type={InputType.TEXT}
            label={translations.houseNumberLabel}
            showClearButton={false}
            onBlur={() => {
              trackInputChange(EAddressFormInputTypes.HOUSE_NUMBER, addressFormData.houseNumber);
            }}
          />
        </div>
      </div>
      <div className='form-line'>
        <div className='small-input'>
          <Input
            testId='zip-code-input'
            required
            showClearButton={false}
            value={addressFormData.zipCode}
            onChange={(event) => handleAddressInputChange(EAddressFormInputTypes.ZIP_CODE, event.target.value)}
            autoComplete='postal-code'
            type={InputType.TEXT}
            label={translations.zipCodeLabel}
            onBlur={() => {
              trackInputChange(EAddressFormInputTypes.ZIP_CODE, addressFormData.zipCode);
            }}
          />
        </div>
        <Input
          testId='city-input'
          required
          showClearButton={false}
          value={addressFormData.city}
          onChange={(event) => handleAddressInputChange(EAddressFormInputTypes.CITY, event.target.value)}
          autoComplete='address-level2'
          type={InputType.TEXT}
          label={translations.cityLabel}
          onBlur={() => {
            trackInputChange(EAddressFormInputTypes.CITY, addressFormData.city);
          }}
        />
      </div>

      <div className='form-line'>
        <Input
          testId='care-of-input'
          showClearButton={false}
          value={addressFormData.careOf}
          onChange={(event) => handleAddressInputChange(EAddressFormInputTypes.CARE_OF, event.target.value)}
          type={InputType.TEXT}
          label={translations.careOfLabel}
          onBlur={() => {
            trackInputChange(EAddressFormInputTypes.CARE_OF, addressFormData.careOf);
          }}
        />
      </div>

      <div className='form-line'>
        <Input
          testId='country-input'
          required
          showClearButton={false}
          value={addressFormData.country}
          onChange={(event) => handleAddressInputChange(EAddressFormInputTypes.COUNTRY, event.target.value)}
          autoComplete='country'
          type={InputType.TEXT}
          label={translations.countryLabel}
          onBlur={() => {
            trackInputChange(EAddressFormInputTypes.COUNTRY, addressFormData.country);
          }}
        />
      </div>
    </div>
  );
};

export default AddressForm;
