import { useIntl } from 'react-intl';

import ModalBody from './ModalBody';

const VaccineOutOfStock = (props: { onButtonClick: Function }) => {
  const { formatMessage } = useIntl();

  const header = formatMessage({
    id: 'Modals.VaccineOutOfStock.header',
    description: 'Header for VaccineOutOfStock modal',
    defaultMessage: 'Alle Termine bereits vergeben',
  });

  const message = formatMessage({
    id: 'Modals.VaccineOutOfStock.message',
    description: 'Message for VaccineOutOfStock modal',
    defaultMessage:
      'Leider sind schon alle Termine mit diesem Impfstoff ausgebucht. Alternativ können Sie noch einen Termin mit einem anderen Impfstoffen auswählen.',
  });

  const ctaButtonLabel = formatMessage({
    id: 'Modals.VaccineOutOfStock.ctaButton',
    description: 'Cta for VaccineOutOfStock modal',
    defaultMessage: 'Ja, gerne',
  });

  const cancelButtonLabel = formatMessage({
    id: 'Modals.VaccineOutOfStock.cancelButton',
    description: 'CancelButton for VaccineOutOfStock modal',
    defaultMessage: 'Nein Danke',
  });

  return (
    <ModalBody
      header={header}
      message={message}
      primaryButtonLabel={ctaButtonLabel}
      onPrimaryButtonClick={() => props.onButtonClick('new_vaccine')}
      secondaryButtonLabel={cancelButtonLabel}
      onSecondaryButtonClick={() => props.onButtonClick('cancel')}
    />
  );
};

export default VaccineOutOfStock;
