import React from 'react';

import './_styles.scss';

export type TCountDownTimerProps = {
  start: Date;
  end: Date;
  lapsingPercentage: number;
  timerLabel?: string;
  complete: boolean;
};

const CountDownTimer = ({ start, end, lapsingPercentage, timerLabel, complete }: TCountDownTimerProps) => {
  const now = new Date();
  const delta1 = now.getTime() - start.getTime();
  const delta2 = end.getTime() - start.getTime();
  const progress = (delta1 / delta2) * 100;
  const showLapsing = !complete && lapsingPercentage <= progress;
  return (
    <div className={`pwa-timer-container ${complete ? 'lapsed' : ''} ${showLapsing ? 'lapsing' : ''}`}>
      <div className='pwa-timer'>
        <div className='pwa-counter' style={{ width: `${progress}%` }}></div>
      </div>
      {timerLabel && <p className='pwa-counter-label'>{timerLabel}</p>}
    </div>
  );
};

export default CountDownTimer;
