import { useMemo } from 'react';
import { useIntl } from 'react-intl';

import { EReferralOptionsType } from './types';

export const useReferralQuestionModalTranslations = () => {
  const { formatMessage } = useIntl();

  const translations = useMemo(
    () => ({
      header: formatMessage({
        id: 'Modals.ReferralQuestion.header',
        description: 'Header for Referral Question modal',
        defaultMessage: 'Eine letzte Frage noch!',
      }),
      message: formatMessage({
        id: 'Modals.ReferralQuestion.message',
        description: 'Message for Referral Question modal',
        defaultMessage:
          'Bitte helfen Sie uns zu verstehen, wie Sie auf uns aufmerksam geworden sind. Ihr Feedback ist uns wichtig.',
      }),
      primaryButtonLabel: formatMessage({
        id: 'Modals.ReferralQuestion.ctaButton',
        description: 'Cta for primary button on Referral Question modal',
        defaultMessage: 'Absenden',
      }),
      secondaryButtonLabel: formatMessage({
        id: 'Modals.ReferralQuestion.secondaryButton',
        description: 'Cta for secondary button on Referral Question modal',
        defaultMessage: 'Schließen',
      }),
      options: [
        {
          label: formatMessage({
            id: 'Modals.ReferralQuestion.options.onlineSearch',
            description: 'Online Search - Option for Referral Question modal',
            defaultMessage: 'Online-Suche',
          }),
          value: EReferralOptionsType.ONLINE_SEARCH,
        },
        {
          label: formatMessage({
            id: 'Modals.ReferralQuestion.options.googleMaps',
            description: 'Google Maps - Option for Referral Question modal',
            defaultMessage: 'Google Maps',
          }),
          value: EReferralOptionsType.GOOGLE_MAPS,
        },
        {
          label: formatMessage({
            id: 'Modals.ReferralQuestion.options.instagram',
            description: 'Instagram - Option for Referral Question modal',
            defaultMessage: 'Instagram',
          }),
          value: EReferralOptionsType.INSTAGRAM,
        },
        {
          label: formatMessage({
            id: 'Modals.ReferralQuestion.options.facebook',
            description: 'Facebook - Option for Referral Question modal',
            defaultMessage: 'Facebook',
          }),
          value: EReferralOptionsType.FACEBOOK,
        },
        {
          label: formatMessage({
            id: 'Modals.ReferralQuestion.options.event',
            description: 'Event - Option for Referral Question modal',
            defaultMessage: 'Event',
          }),
          value: EReferralOptionsType.EVENT,
        },
        {
          label: formatMessage({
            id: 'Modals.ReferralQuestion.options.outdoorAds',
            description: 'Outdoor Ads - Option for Referral Question modal',
            defaultMessage: 'Plakate & Schilder',
          }),
          value: EReferralOptionsType.OUTDOOR_ADS,
        },
        {
          label: formatMessage({
            id: 'Modals.ReferralQuestion.options.mailings',
            description: 'Mailings - Option for Referral Question modal',
            defaultMessage: 'Information per Post',
          }),
          value: EReferralOptionsType.MAILINGS,
        },
        {
          label: formatMessage({
            id: 'Modals.ReferralQuestion.options.flyers',
            description: 'Flyers - Option for Referral Question modal',
            defaultMessage: 'Flyer',
          }),
          value: EReferralOptionsType.FLYERS,
        },
        {
          label: formatMessage({
            id: 'Modals.ReferralQuestion.options.printAds',
            description: 'Print Ads - Option for Referral Question modal',
            defaultMessage: 'Printwerbung',
          }),
          value: EReferralOptionsType.PRINT_ADS,
        },
        {
          label: formatMessage({
            id: 'Modals.ReferralQuestion.options.friendsAndFamily',
            description: 'Friends and Family - Option for Referral Question modal',
            defaultMessage: 'Empfehlung durch Freunde oder Familie',
          }),
          value: EReferralOptionsType.FRIENDS_AND_FAMILY,
        },
        {
          label: formatMessage({
            id: 'Modals.ReferralQuestion.options.medicalReferral',
            description: 'Medical Referral - Option for Referral Question modal',
            defaultMessage: 'Ärztliche Empfehlung oder Überweisung',
          }),
          value: EReferralOptionsType.MEDICAL_REFERRAL,
        },
        {
          label: formatMessage({
            id: 'Modals.ReferralQuestion.options.jameda',
            description: 'Jameda - Option for Referral Question modal',
            defaultMessage: 'Jameda',
          }),
          value: EReferralOptionsType.JAMEDA,
        },
        {
          label: formatMessage({
            id: 'Modals.ReferralQuestion.options.other',
            description: 'Other - Option for Referral Question modal',
            defaultMessage: 'Sonstige',
          }),
          value: EReferralOptionsType.OTHER,
        },
      ],
      otherPlaceholder: formatMessage({
        id: 'Modals.ReferralQuestion.otherPlaceholder',
        description: 'Placeholder for other option on Referral Question modal',
        defaultMessage: 'Bitte spezifizieren',
      }),
    }),
    [formatMessage]
  );

  return translations;
};
