import { EInsuranceType } from '../../type';

import Button, { ButtonType } from 'components/Button/Button';

import { useInsuranceSelectionTranslations } from '../../InsuranceSelection.translations';

import './styles.scss';

type TProps = {
  onInsuranceTypeSelected: (insuranceType: EInsuranceType) => void;
};

const NoInsuranceSearchResult = ({ onInsuranceTypeSelected }: TProps) => {
  const translations = useInsuranceSelectionTranslations();
  return (
    <div className='no-search-result'>
      <p data-testid='no-search-match-title' className='no-match-title'>
        {translations.noResultTitle}
      </p>
      <div className='options-container'>
        <Button
          data-testid='gkv-option'
          onClick={() => onInsuranceTypeSelected(EInsuranceType.GKV)}
          styles={{ width: 'fit-content', padding: 0 }}
          type={ButtonType.LINK}
          label={translations.publicInsuranceLabel}
        />
        <Button
          data-testid='pkv-option'
          onClick={() => onInsuranceTypeSelected(EInsuranceType.PKV)}
          styles={{ width: 'fit-content', padding: 0 }}
          type={ButtonType.LINK}
          label={translations.privateInsuranceLabel}
        />
      </div>
    </div>
  );
};

export default NoInsuranceSearchResult;
