import { ReactElement } from 'react';
import { Helmet } from 'react-helmet-async';

import './styles.scss';

type TProps = {
  documentTitle?: string;
  SideContent?: ReactElement;
  children: ReactElement | ReactElement[];
};

const BookingPageContainer = ({ documentTitle, SideContent, children }: TProps) => {
  return (
    <>
      <Helmet>
        <title>{documentTitle}</title>
        <meta name='description' content={documentTitle} />
      </Helmet>

      <div className='page-container'>
        {children}
        {SideContent ? (
          <div data-testid='side-content' className='side-content-container'>
            {SideContent}
          </div>
        ) : null}
      </div>
    </>
  );
};

export default BookingPageContainer;
