import { useIntl } from 'react-intl';

import ModalBody from './ModalBody';

const AppointmentReserved = (props: { onButtonClick: Function }) => {
  const { formatMessage } = useIntl();

  const header = formatMessage({
    id: 'Modals.AppointmentReserved.header',
    description: 'Header for AppointmentReserved modal',
    defaultMessage: 'Termin vorläufig reserviert',
  });

  const message = formatMessage({
    id: 'Modals.AppointmentReserved.message',
    description: 'Message for AppointmentReserved modal',
    defaultMessage:
      'Wir halten Ihnen den Termin für die nächsten 15 Minuten frei. Um Ihre Buchung abzuschließen, benötigen wir noch ein paar persönliche Daten.',
  });

  const ctaButtonLabel = formatMessage({
    id: 'Modals.AppointmentReserved.ctaButton',
    description: 'Cta for AppointmentReserved modal',
    defaultMessage: 'Termin bestätigen',
  });

  const editButtonLabel = formatMessage({
    id: 'Modals.AppointmentReserved.editButton',
    description: 'EditButton for AppointmentReserved modal',
    defaultMessage: 'Terminauswahl bearbeiten',
  });

  return (
    <ModalBody
      header={header}
      message={message}
      primaryButtonLabel={ctaButtonLabel}
      onPrimaryButtonClick={() => props.onButtonClick('register')}
      secondaryButtonLabel={editButtonLabel}
      onSecondaryButtonClick={() => props.onButtonClick('edit')}
    />
  );
};

export default AppointmentReserved;
