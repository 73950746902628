import useAppContext from 'AppContext';
import { useIntl } from 'react-intl';
import { useNavigate } from 'react-router-dom';

import { AppRoutes } from 'utils/routeUtils';

import { useCountlyConfig } from 'modules/countly/hooks';

import ModalBody from './ModalBody';

export enum CancellationModalType {
  CANCELLED,
  APPOINTMENT_NOT_FOUND,
}

const AppointmentCancellation = (props: { onButtonClick: Function; cancellationModalType: CancellationModalType }) => {
  const { resetContext } = useAppContext();
  const navigate = useNavigate();
  const { formatMessage } = useIntl();
  const { countlyConfig } = useCountlyConfig();

  const primaryBtnLabel = formatMessage({
    id: 'Modals.AppointmentCancelled.primaryBtnLabel',
    description: 'Label for AppointmentCancellation primary button',
    defaultMessage: 'Neuen Termin finden',
  });

  const secondaryBtnLabel = formatMessage({
    id: 'Modals.AppointmentCancellation.secondaryBtnLabel',
    description: 'Label for AppointmentCancellation secondary button',
    defaultMessage: 'Schließen',
  });

  let header, message;

  if (props.cancellationModalType === CancellationModalType.CANCELLED) {
    header = formatMessage({
      id: 'Modals.AppointmentCancelled.header',
      description: 'Header for AppointmentCancelled modal',
      defaultMessage: 'Ihr Termin ist abgesagt',
    });

    message = formatMessage({
      id: 'Modals.AppointmentCancelled.message',
      description: 'Message for AppointmentCancelled modal',
      defaultMessage: 'Möchten Sie einen neuen Termin bei avi vereinbaren?',
    });
  } else {
    header = formatMessage({
      id: 'Modals.AppointmentNotFound.header',
      description: 'Header for AppointmentNotFound modal',
      defaultMessage: 'Termin nicht gefunden',
    });

    message = formatMessage({
      id: 'Modals.AppointmentNotFound.message',
      description: 'Message for AppointmentNotFound modal',
      defaultMessage: 'Vielleicht wurde Ihr Termin bereits abgesagt. Möchten Sie einen neuen Termin vereinbaren?',
    });
  }

  return (
    <ModalBody
      header={header}
      message={message}
      primaryButtonLabel={primaryBtnLabel}
      onPrimaryButtonClick={() => {
        resetContext();
        navigate(countlyConfig?.ask_for_patient_return_type ? AppRoutes.IsFirstAppointment() : AppRoutes.SelectRfe());
      }}
      secondaryButtonLabel={secondaryBtnLabel}
      onSecondaryButtonClick={() => props.onButtonClick('close')}
    />
  );
};

export default AppointmentCancellation;
