import IDoctor from 'interfaces/Doctor';

const DEFAULT_EMPTY_DOCTOR: IDoctor = {
  id: 0,
  name: '',
  specialties: [],
  languages: [],
};

const DEFAULT_DOCTOR_ID = 0;

export { DEFAULT_EMPTY_DOCTOR, DEFAULT_DOCTOR_ID };
