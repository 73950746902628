import api from 'services/api';

import Region from 'interfaces/Region';

export const getRegions = async (locale: string): Promise<Region[]> => {
  try {
    const { data } = await api.get(`/region?locale=${locale}`);
    return data?.regions || [];
  } catch (err) {
    throw err;
  }
};
