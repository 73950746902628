import { useEffect } from 'react';

import { trackEvent } from 'utils/analyticsUtils';

import { useAppRefreshNeededTranslations } from './index.translations';

import ModalBody from '../ModalBody';

const AppRefreshNeeded = () => {
  const translations = useAppRefreshNeededTranslations();

  useEffect(() => {
    trackEvent({
      category: 'View',
      action: 'App-Need-Refresh',
    });
  }, []);

  const handleOnButtonClick = () => {
    window.location.reload();
  };

  return (
    <ModalBody
      testId='modal-app-refresh-needed-content'
      header={translations.header}
      message={translations.message}
      primaryButtonLabel={translations.ctaButtonLabel}
      onPrimaryButtonClick={handleOnButtonClick}
    />
  );
};

export default AppRefreshNeeded;
