import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import {
  TConfirmAppointmentPayload,
  TCreateAppointmentPayload,
  createAppointment,
  deleteAppointment,
  getAppointment,
  confirmAppointment as requestConfirmAppointment,
} from 'services/appointment';

import { TAppointmentState } from 'interfaces/Appointment';

export const reserveAppointment = createAsyncThunk(
  'appointment/reserveAppointment',
  async (appointmentData: TCreateAppointmentPayload) => {
    return createAppointment(appointmentData);
  }
);

export const confirmAppointment = createAsyncThunk(
  'appointment/confirmAppointment',
  async (params: TConfirmAppointmentPayload) => requestConfirmAppointment(params)
);

export const fetchAppointment = createAsyncThunk(
  'appointment/fetchAppointment',
  async (params: { appointmentId?: string; token?: string | null; uniqueEmailId?: string | null; locale?: string }) => {
    return getAppointment(params.appointmentId, params.token, params.uniqueEmailId, params.locale);
  }
);

export const freeAppointment = createAsyncThunk(
  'appointment/freeAppointment',
  async (params: { appointmentId: string; uniqueAppId?: string; uniqueEmailId?: string | null }) => {
    return deleteAppointment(params.appointmentId, params.uniqueAppId, params.uniqueEmailId);
  }
);

const initialState = {
  appointment: {},
  isFetching: false,
  isFetched: false,
  error: null,
} as TAppointmentState;

const appointmentSlice = createSlice({
  name: 'appointment',
  initialState,
  reducers: {
    clearAppointment: (state) => {
      state.appointment = {};
      state.isFetching = false;
      state.isFetched = false;
      state.error = null;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(reserveAppointment.pending, (state) => {
      state.error = null;
      state.isFetching = true;
      state.isFetched = false;
    });
    builder.addCase(reserveAppointment.fulfilled, (state, action) => {
      state.appointment = action.payload.data;
      state.error = null;
      state.isFetched = true;
      state.isFetching = false;
    });
    builder.addCase(reserveAppointment.rejected, (state, action) => {
      state.appointment = {};
      state.error = true;
      state.isFetched = true;
      state.isFetching = false;
    });
    builder.addCase(confirmAppointment.pending, (state) => {
      state.error = null;
      state.isFetching = true;
      state.isFetched = false;
    });
    builder.addCase(confirmAppointment.fulfilled, (state, action) => {
      state.appointment = action.payload.data;
      state.error = null;
      state.isFetched = true;
      state.isFetching = false;
    });
    builder.addCase(confirmAppointment.rejected, (state, action) => {
      state.error = action;
      state.isFetched = true;
      state.isFetching = false;
    });
    builder.addCase(fetchAppointment.pending, (state) => {
      state.error = null;
      state.isFetching = true;
      state.isFetched = false;
    });
    builder.addCase(fetchAppointment.fulfilled, (state, action) => {
      state.appointment = action.payload;
      state.error = null;
      state.isFetched = true;
      state.isFetching = false;
    });
    builder.addCase(fetchAppointment.rejected, (state, action) => {
      state.appointment = {};
      state.error = action;
      state.isFetched = true;
      state.isFetching = false;
    });
    builder.addCase(freeAppointment.pending, (state) => {
      state.error = null;
      state.isFetching = true;
      state.isFetched = false;
    });
    builder.addCase(freeAppointment.fulfilled, (state, action) => {
      state.appointment = {};
      state.error = null;
      state.isFetched = true;
      state.isFetching = false;
    });
    builder.addCase(freeAppointment.rejected, (state, action) => {
      state.appointment = {};
      state.error = action;
      state.isFetched = true;
      state.isFetching = false;
    });
  },
});

export const { clearAppointment } = appointmentSlice.actions;
export default appointmentSlice.reducer;
