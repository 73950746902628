import { ENV } from 'config';

/**
 * Wrapper util around console to log only in non-prod environments
 * @memberof utils
 * @name lowerEnvLogger
 * @returns {Object} - Object containing info, log, warn, and error functions
 */
const lowerEnvLogger = () => {
  const info = (...data: any[]) => {
    if (ENV !== 'PROD') console.info(...data);
  };
  const log = (...data: any[]) => {
    if (ENV !== 'PROD') console.log(...data);
  };
  const warn = (...data: any[]) => {
    if (ENV !== 'PROD') console.warn(...data);
  };
  const error = (...data: any[]) => {
    if (ENV !== 'PROD') console.error(...data);
  };

  return { info, log, warn, error };
};

export default lowerEnvLogger();
