import api from 'services/api';

import { TPractice, TPracticeData } from 'interfaces/Practice';

import { getLocale } from 'utils';

export const getPractice = async (practiceId: string, locale?: string): Promise<TPracticeData> => {
  try {
    const lang = locale || getLocale();
    const { data } = await api.get(`/practice/${practiceId}?locale=${lang}`);
    return data;
  } catch (err) {
    throw err;
  }
};

export const getPractices = async (locale?: string): Promise<TPractice[]> => {
  try {
    const lang = locale || getLocale();
    const { data } = await api.get(`/practice?locale=${lang}`);
    return data;
  } catch (err) {
    throw err;
  }
};
