import { useIntl } from 'react-intl';

import ModalBody from './ModalBody';

interface IProps {
  onButtonClick: (btn: string) => void;
}

const VideoCallNotFound = (props: IProps) => {
  const { formatMessage } = useIntl();
  const header = formatMessage({
    id: 'Modals.VideoCallNotFound.header',
    description: 'Header for VideoCallNotFound modal',
    defaultMessage: 'Videosprechstunde nicht gefunden',
  });

  const message = formatMessage({
    id: 'Modals.VideoCallNotFound.message',
    description: 'Message for VideoCallNotFound modal',
    defaultMessage: 'Leider konnten wir Ihren Videosprechstunde nicht finden.',
  });

  const ctaButtonLabel = formatMessage({
    id: 'Modals.VideoCallNotFound.ctaButton',
    description: 'Cta for VideoCallNotFound modal',
    defaultMessage: 'Schließen',
  });

  return (
    <ModalBody
      header={header}
      message={message}
      primaryButtonLabel={ctaButtonLabel}
      onPrimaryButtonClick={() => props.onButtonClick('close')}
    />
  );
};

export default VideoCallNotFound;
