import dayjs from 'dayjs';
import 'dayjs/locale/de';
import 'dayjs/locale/en';
import React, { PropsWithChildren, createContext, useState } from 'react';
import { IntlProvider as ReactIntlProvider } from 'react-intl';
import MESSAGES_DE from 'translations/de.json';
import MESSAGES_EN from 'translations/en.json';

export type TLanguage = 'en' | 'de';
type TIntl = { language: TLanguage; changeLanguage: Function };

const LANGUAGE_EN: TLanguage = 'en';
const LANGUAGE_DE: TLanguage = 'de';

const defaultLanguage: TLanguage = LANGUAGE_EN;
const defaultIntl: TIntl = {
  language: defaultLanguage,
  changeLanguage: () => {
    // This is intentional
  },
};

export const IntlContext = createContext(defaultIntl);

export const IntlProvider: React.FC<PropsWithChildren<{ locale?: TLanguage }>> = ({ children, locale: localeProp }) => {
  const locale = localeProp || localStorage.getItem('locale') || navigator.language;
  const html = document.querySelector('html');
  const isDE = locale && locale.startsWith('de');

  const [language, setLanguage] = useState<TLanguage>(isDE ? 'de' : 'en');
  const [messages, setMessages] = useState<any>(isDE ? MESSAGES_DE : MESSAGES_EN);

  if (isDE) {
    dayjs.locale(LANGUAGE_DE);
    html?.setAttribute('lang', 'de');
  } else {
    dayjs.locale(LANGUAGE_EN);
    html?.setAttribute('lang', 'en');
  }

  const handleChangeLanguage = (lang: TLanguage) => {
    localStorage.setItem('locale', lang);
    setLanguage(lang);
    setMessages(lang === 'de' ? MESSAGES_DE : MESSAGES_EN);
    dayjs.locale(lang);
  };

  return (
    <IntlContext.Provider value={{ language, changeLanguage: handleChangeLanguage }}>
      <ReactIntlProvider messages={messages} locale={language}>
        {children}
      </ReactIntlProvider>
    </IntlContext.Provider>
  );
};
