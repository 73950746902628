import { captureException } from '@sentry/react';
import React, { PropsWithChildren } from 'react';

import ModalDialog, { ModalDialogType } from '../ModalDialog/ModalDialog';

export const CHUNK_LOAD_ERROR_NAME = 'ChunkLoadError';

class GlobalErrorBoundary extends React.Component<PropsWithChildren, { hasChunkLoadError: boolean }> {
  constructor(props: { children: JSX.Element }) {
    super(props);
    this.state = { hasChunkLoadError: false };
  }

  componentDidCatch(error: Error) {
    captureException(error);
    // Currently, we are only acting on chunk load error
    // (when a chunk is unavailable because we updated the application while the user had the web app opened)
    // We'll display a UI to inform the user with a button to refresh the web app
    if (error.name === CHUNK_LOAD_ERROR_NAME) {
      this.setState({ hasChunkLoadError: true });
    }
  }

  render() {
    if (this.state.hasChunkLoadError) {
      return <ModalDialog type={ModalDialogType.APP_REFRESH_NEEDED} />;
    }
    return this.props.children;
  }
}

export default GlobalErrorBoundary;
