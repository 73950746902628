import LoadingImg from 'assets/images/spinner.svg';
import { useCallback } from 'react';

import { EInsuranceSelectionError, EInsuranceType, TAddressFormData, TInsurance, TInsuranceFormData } from './type';

import BackButton from 'components/Button/BackButton';
import ContinueButton from 'components/Button/ContinueButton';
import Header from 'components/Header';
import Search from 'components/Search';
import SomethingWentWrong from 'components/SomethingWentWrong';

import AddressForm from './components/AddressForm';
import InsuranceForm from './components/InsuranceForm';
import InsuranceSuggestion from './components/InsuranceSuggestion';
import NoInsuranceSearchResult from './components/NoInsuranceSearchResult';

import { useInsuranceSelectionTranslations } from './InsuranceSelection.translations';

import './styles.scss';

export type TInsuranceSelectionUI = {
  insuranceSuggestions: TInsurance[];
  selectedInsurance: TInsurance | null;
  isLoadingInsurances: boolean;
  isContinueDisabled: boolean;
  onSearchInput: (query: string) => void;
  onSelectInsurance: (insurance: TInsurance | null) => void;
  onGoBack: () => void;
  onContinue: () => void;
  errors: {
    [key in EInsuranceSelectionError]: Error | null;
  };
  query: string;
  setQuery: (query: string) => void;
  showSuggestionList: boolean;
  setShowSuggestionList: (show: boolean) => void;
  insuranceFormData: TInsuranceFormData;
  updateInsuranceFormData: (formData: Partial<TInsuranceFormData>) => void;
  addressFormData: TAddressFormData;
  updateAddressFormData: (formData: Partial<TAddressFormData>) => void;
};

const InsuranceSelectionUi = ({
  insuranceSuggestions,
  selectedInsurance,
  isLoadingInsurances,
  isContinueDisabled,
  insuranceFormData,
  updateInsuranceFormData,
  addressFormData,
  updateAddressFormData,
  onSelectInsurance,
  onSearchInput,
  onGoBack,
  onContinue,
  errors,
  query,
  setQuery,
  showSuggestionList,
  setShowSuggestionList,
}: TInsuranceSelectionUI) => {
  const translations = useInsuranceSelectionTranslations();

  const error = Object.keys(errors).find((key) => errors[key as EInsuranceSelectionError] !== null);

  const NoResultComponent = useCallback(() => {
    const handleSelectInsuranceType = (insuranceType: EInsuranceType) => {
      onSelectInsurance({
        type: insuranceType.toString(),
        name: query,
        suggestion: false,
      });
      setShowSuggestionList(false);
    };

    return <NoInsuranceSearchResult onInsuranceTypeSelected={handleSelectInsuranceType} />;
  }, [onSelectInsurance, query, setShowSuggestionList]);

  return (
    <section className='insurance-selection'>
      <div className='page-content'>
        <Header title={translations.title} subtitle={translations.description} />

        {error && (
          <SomethingWentWrong
            description={translations[error as EInsuranceSelectionError]}
            error={
              errors[EInsuranceSelectionError.INSURANCE_ERROR] || errors[EInsuranceSelectionError.VALIDATION_ERROR]
            }
            parentScreen='InsuranceSelection'
          />
        )}

        {!error && isLoadingInsurances ? (
          <div className='loader-container'>
            <img data-testid='loading-icon' src={LoadingImg} alt='loading icon' width='24' height='24' />
          </div>
        ) : null}
        {!error && !isLoadingInsurances ? (
          <Search<TInsurance>
            query={query}
            setQuery={setQuery}
            showSuggestionList={showSuggestionList}
            setShowSuggestionList={setShowSuggestionList}
            placeholder={translations.placeholder}
            suggestions={insuranceSuggestions}
            onSearchInput={onSearchInput}
            onSelectSuggestion={onSelectInsurance}
            maxLength={100}
            debounceTime={0}
            SuggestionComponent={InsuranceSuggestion}
            NoResultComponent={NoResultComponent}
            isFetchingSuggestions={isLoadingInsurances}
            testId='insurance-search-input'
          />
        ) : null}

        <form name='insurance-form' className='insurance-form-container'>
          {selectedInsurance && selectedInsurance.type === 'GKV' && (
            <InsuranceForm insuranceFormData={insuranceFormData} updateInsuranceFormData={updateInsuranceFormData} />
          )}

          {selectedInsurance && (
            <AddressForm addressFormData={addressFormData} updateAddressFormData={updateAddressFormData} />
          )}
        </form>
      </div>
      <div className='fixed-buttons buttons'>
        <BackButton onClick={onGoBack} />
        <ContinueButton testid='continue-button' onClick={onContinue} disabled={isContinueDisabled} />
      </div>
    </section>
  );
};

export default InsuranceSelectionUi;
