import { combineReducers, configureStore } from '@reduxjs/toolkit';
import { createReduxEnhancer } from '@sentry/react';
import { ENV } from 'config';
import { useDispatch } from 'react-redux';
import { FLUSH, PAUSE, PERSIST, PURGE, REGISTER, REHYDRATE, persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage/session';
import addressReducer from 'redux/Slices/address';
import appointmentReducer from 'redux/Slices/appointment';
import insuranceReducer from 'redux/Slices/insurance';

import practiceReducer from '../Slices/practice';
import regionReducer from '../Slices/regions';

export const reducers = combineReducers({
  regionReducer,
  practiceReducer,
  appointmentReducer,
  insuranceReducer,
  addressReducer,
});

const persistConfig = {
  key: 'root',
  storage,
  timeout: 50,
};

const persistedReducer = persistReducer(persistConfig, reducers);

const sentryReduxEnhancer = createReduxEnhancer({});

export const store = configureStore({
  reducer: persistedReducer,
  devTools: ENV !== 'PROD',
  enhancers: (defaultEnhancers) => {
    return defaultEnhancers.concat(sentryReduxEnhancer);
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: {
        ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
      },
    }),
});

export type TRootState = ReturnType<typeof store.getState>;
export type TAppDispatch = typeof store.dispatch;
export const useAppDispatch: () => TAppDispatch = useDispatch;
