import { langsByCode } from '@avimedical/langs';
import { CircleFlag } from 'react-circle-flags';

import { TDoctorInfoProps } from 'components/Content/DoctorInfo';

const DoctorOption = ({ name, position, picture, languages, specialties }: TDoctorInfoProps) => {
  return (
    <div className='pwa-doctor-option'>
      <div className='info'>
        <div className='name'>
          <h3>{name}</h3>
          <span className='position'>{position}</span>
          <div className='langs'>
            {languages?.map((code) => {
              const lang = langsByCode.get(code.toLowerCase());
              // 'xx' is placeholder flag: https://github.com/HatScripts/circle-flags/blob/gh-pages/flags/xx.svg
              const countryCode = lang?.countryCode ?? 'xx';
              const languageName = lang?.nameLocal ?? code;

              return (
                <span key={code} className='flag-and-name'>
                  <CircleFlag key={code} countryCode={countryCode} height='13' />
                  <p className='lang-name-no-white-space'>
                    <span>{languageName}</span>
                  </p>
                </span>
              );
            })}
          </div>
        </div>
        <h4 className='subtitle2'>{specialties?.join(', ')}</h4>
      </div>
      {picture && <div className='photo' style={{ backgroundImage: 'url(' + picture + ')' }} />}
    </div>
  );
};

export default DoctorOption;
