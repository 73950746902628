import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import { TZipCodeValidation } from './type';

import { validateZipCodeApi } from './api/addressApi';

type TAddressSlice = {
  zipCodeValidation: {
    result: TZipCodeValidation | null;
    isFetching: boolean;
    isFetched: boolean;
    error: Error | null;
  };
};

export const validateZipCode = createAsyncThunk('address/zipcode/validate', async (zipCode: string) => {
  return await validateZipCodeApi(zipCode);
});

const initialState: TAddressSlice = {
  zipCodeValidation: {
    result: null,
    isFetching: false,
    isFetched: false,
    error: null,
  },
};

const addressSlice = createSlice({
  name: 'address',
  initialState,
  reducers: {
    resetZipCodeValidation: () => initialState,
  },
  extraReducers: (builder) => {
    builder.addCase(validateZipCode.pending, (state) => {
      state.zipCodeValidation = {
        isFetching: true,
        isFetched: false,
        error: null,
        result: null,
      };
    });
    builder.addCase(validateZipCode.fulfilled, (state, action) => {
      state.zipCodeValidation = {
        isFetched: true,
        result: action.payload,
        isFetching: false,
        error: null,
      };
    });
    builder.addCase(validateZipCode.rejected, (state, action) => {
      state.zipCodeValidation = {
        isFetched: true,
        result: null,
        isFetching: false,
        error: action.error as Error,
      };
    });
  },
});
export default addressSlice.reducer;
export const { resetZipCodeValidation } = addressSlice.actions;
