import { TPracticeData } from 'interfaces/Practice';

const mapPracticeStaff = (practice: TPracticeData) => {
  return practice?.staff?.map((staff: any) => {
    return {
      id: staff.id,
      name: `${staff.title ? staff.title + ' ' : ''}${staff.firstName} ${staff.lastName}`,
      position: staff.position,
      languages: staff.languages,
      picture: staff.photo,
      role: staff.role,
    };
  });
};

export { mapPracticeStaff };
