import { useIntl } from 'react-intl';

import ModalBody from './ModalBody';

const RegistrationConfirm = (props: { onButtonClick: Function }) => {
  const { formatMessage } = useIntl();

  const header = formatMessage({
    id: 'Modals.WaitingList.header',
    description: 'Header for Corona vaccination waiting list modal',
    defaultMessage: 'Coronaimpfung Warteliste',
  });

  const message = formatMessage({
    id: 'Modals.WaitingList.message',
    description: 'Message for Corona vaccination waiting list modal',
    defaultMessage:
      'Bitte trag dich auf die Warteliste an sollte kein Impfstoff verfügbar sein und wir melden uns dann bei dir, sobald wir dich impfen können.',
  });

  const ctaButtonLabel = formatMessage({
    id: 'Modals.WaitingList.ctaButton',
    description: 'Cta for Corona vaccination waiting list modal',
    defaultMessage: 'Zur Warteliste',
  });

  return (
    <ModalBody
      header={header}
      message={message}
      primaryButtonLabel={ctaButtonLabel}
      onPrimaryButtonClick={() => props.onButtonClick('link')}
    />
  );
};

export default RegistrationConfirm;
