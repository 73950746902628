import { useIntl } from 'react-intl';

export const useSuggestionListTranslations = () => {
  const { formatMessage } = useIntl();

  return {
    frequentlySearchLabel: formatMessage({
      id: 'Components.SuggestionList.frequentlySearchLabel',
      description: 'Label for frequently search',
      defaultMessage: 'Oft angegeben',
    }),
  };
};
