import './styles.scss';

type TProps = {
  title: string | JSX.Element | (string | JSX.Element)[];
  size: 'sm' | 'md' | 'lg' | 'xl';
  testId?: Lowercase<string>;
};

const sizeToHeadingMap: { [key: string]: keyof JSX.IntrinsicElements } = {
  sm: 'h4',
  md: 'h3',
  lg: 'h2',
  xl: 'h1',
};

const Title = ({ title, size, testId = 'title' }: TProps) => {
  const Heading = sizeToHeadingMap[size];
  return (
    <Heading className={`title ${size}`} data-testid={testId}>
      {title}
    </Heading>
  );
};

export default Title;
