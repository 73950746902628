import { useIntl } from 'react-intl';

export const useAppRefreshNeededTranslations = () => {
  const { formatMessage } = useIntl();

  return {
    header: formatMessage({
      id: 'Modals.AppRefreshNeeded.header',
      description: 'Header for AppRefreshNeeded modal',
      defaultMessage: 'Die Seite muss neu geladen werden',
    }),
    message: formatMessage({
      id: 'Modals.AppRefreshNeeded.message',
      description: 'Message for AppRefreshNeeded modal',
      defaultMessage: 'Leider ist auf unserer Seite etwas schiefgelaufen.',
    }),
    ctaButtonLabel: formatMessage({
      id: 'Modals.AppRefreshNeeded.ctaButton',
      description: 'Cta for AppRefreshNeeded modal',
      defaultMessage: 'Neu laden',
    }),
  };
};
