import { TAddressFormData } from 'pages/Booking/InsuranceSelection/type';

export const getNormalisedAddress = (
  addressComponents: Exclude<google.maps.places.PlaceResult['address_components'], undefined>
) => {
  return addressComponents.reduce(
    (address: Partial<TAddressFormData>, comp) => {
      if (comp.types.includes('street_number')) {
        address.houseNumber = comp.long_name;
      }
      if (comp.types.includes('locality')) {
        address.city = comp.long_name;
      }
      if (comp.types.includes('postal_code')) {
        address.zipCode = comp.long_name;
      }
      if (comp.types.includes('route')) {
        address.street = comp.long_name;
      }
      if (comp.types.includes('country')) {
        address.country = comp.long_name;
      }
      return address;
    },
    {
      street: '',
      houseNumber: '',
      city: '',
      zipCode: '',
      country: '',
    }
  );
};
