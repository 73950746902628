import classNames from 'classnames';
import { ReactNode } from 'react';

import Button, { ButtonType } from 'components/Button/Button';

import styles from './modal.module.scss';

type TModalBodyProps = {
  header?: string;
  message?: string | ReactNode;
  body?: ReactNode;
  testId?: LCString;
  primaryButtonLabel?: string;
  onPrimaryButtonClick?: (param: any) => void;
  secondaryButtonLabel?: string;
  onSecondaryButtonClick?: (param: any) => void;
  buttonRowLayout?: boolean;
  isPrimaryButtonDisabled?: boolean;
};

const ModalBody = (props: TModalBodyProps) => {
  const {
    testId,
    header,
    message,
    body,
    primaryButtonLabel,
    secondaryButtonLabel,
    onPrimaryButtonClick,
    onSecondaryButtonClick,
    buttonRowLayout = false,
    isPrimaryButtonDisabled = false,
  } = props;

  return (
    <>
      <div data-testid={testId}>
        {header && <h3 className='title-3'>{header}</h3>}
        {message && <p className='modal-dialog-message'>{message}</p>}
      </div>
      {body && <div className={styles.body}>{body}</div>}

      <div className={classNames(styles.actions, buttonRowLayout ? styles.rowLayout : null)}>
        {primaryButtonLabel && onPrimaryButtonClick && (
          <Button
            disabled={isPrimaryButtonDisabled}
            type={ButtonType.PRIMARY}
            label={primaryButtonLabel}
            onClick={onPrimaryButtonClick}
          />
        )}
        {onSecondaryButtonClick && secondaryButtonLabel && (
          <span className={styles.link} onClick={onSecondaryButtonClick}>
            {secondaryButtonLabel}
          </span>
        )}
      </div>
    </>
  );
};

export default ModalBody;
