import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import { TInsurance } from '../../../pages/Booking/InsuranceSelection/type';

import { fetchInsurancesApi } from './api/insuranceApi';

type TInsuranceSlice = {
  insurances: TInsurance[] | null;
  isFetching: boolean;
  isFetched: boolean;
  error: Error | null;
};

export const fetchInsurances = createAsyncThunk('insurances/fetchInsurances', async () => {
  return await fetchInsurancesApi();
});

const initialState = {
  insurances: [],
  isFetching: false,
  isFetched: false,
  error: null,
} as TInsuranceSlice;

const insurancesSlice = createSlice({
  name: 'insurances',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(fetchInsurances.pending, (state) => {
      state.error = null;
      state.isFetching = true;
      state.isFetched = false;
    });
    builder.addCase(fetchInsurances.fulfilled, (state, action) => {
      state.error = null;
      state.insurances = action.payload;
      state.isFetched = true;
      state.isFetching = false;
    });
    builder.addCase(fetchInsurances.rejected, (state, action) => {
      state.error = action.error as Error;
      state.isFetched = true;
      state.isFetching = false;
    });
  },
});
export default insurancesSlice.reducer;
