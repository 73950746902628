import { MouseEvent } from 'react';
import { useIntl } from 'react-intl';

import Button, { ButtonType } from './Button';

export default function BackButton(props: { onClick: (e: MouseEvent) => void }) {
  const { formatMessage } = useIntl();

  const label = formatMessage({
    id: 'Components.Button.BackButton.label',
    description: 'Label for back button',
    defaultMessage: 'Zurück',
  });

  const eventOnClick = (e: MouseEvent) => {
    if (props.onClick) props.onClick(e);
  };

  return <Button data-testid='back-button' type={ButtonType.SECONDARY} label={label} onClick={eventOnClick} />;
}
