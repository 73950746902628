import useAppContext from 'AppContext';
import AppointmentTakenIcon from 'assets/images/calendar_fail.svg';
import ReservedIcon from 'assets/images/calendar_ok.svg';
import CloseIcon from 'assets/images/icon_close.svg';
import EmailConfirmIcon from 'assets/images/message_ok.svg';
import RefreshAppIcon from 'assets/images/refresh_app.svg';
import { useEffect, useMemo } from 'react';

import AppointmentCancellation, { CancellationModalType } from './AppointmentCancellation';
import AppointmentReserved from './AppointmentReserved';
import AppointmentTaken from './AppointmentTaken';
import EmailConfirm from './EmailConfirm';
import EmailOk from './EmailOk';
import EmailTaken from './EmailTaken';
import GenericError from './GenericError';
import InvalidCode from './InvalidCode';
import InvalidRFEBookingCode, { InvalidRFEBookingCodeModalType } from './InvalidRFEBookingCode';
import ModalAppRefreshNeeded from './ModalAppRefreshNeeded';
import PhoneDoesNotBelongToPatientError from './PhoneDoesNotBelongToPatientError';
import ReferralQuestion, { TReferralQuestionModalProps } from './ReferralQuestion';
import RegistrationConfirm from './RegistrationConfirm';
import VaccineOutOfStock from './VaccineOutOfStock';
import VideoCallBrowserUnsupported from './VideoCallBrowserUnsupported';
import VideoCallNotFound from './VideoCallNotFound';
import WaitingList from './WaitingList';
import styles from './modal.module.scss';

export enum ModalDialogType {
  APPOINTMENT_RESERVED,
  APPOINTMENT_TAKEN,
  EMAIL_CONFIRM,
  EMAIL_OK,
  EMAIL_TAKEN,
  REGISTRATION_CONFIRMATION,
  INVALID_CODE,
  GENERIC,
  WAITING_LIST,
  APPOINTMENT_CANCELLED,
  APPOINTMENT_NOT_FOUND,
  VACCINE_OUT_OF_STOCK,
  INVALID_RFE_BOOKING_CODE,
  RFE_BOOKING_CODE_REQUIRED,
  VIDEO_CALL_NOT_FOUND,
  VIDEO_CALL_BROWSER_UNSUPPORTED,
  APP_REFRESH_NEEDED,
  REFERRAL_QUESTION,
  PHONE_DOES_NOT_BELONG_TO_PATIENT,
}

type TModalDialogProps = {
  type: ModalDialogType;
  closeable?: boolean;
  visible?: boolean;
  onButtonClicked?: (btn: string) => void;
  email?: string;
  optionalData?: Object;
};

const MODAL_DIALOG_TYPE_MODEL_IMAGE_MAP = new Map([
  [ModalDialogType.APPOINTMENT_RESERVED, ReservedIcon],
  [ModalDialogType.APPOINTMENT_TAKEN, AppointmentTakenIcon],
  [ModalDialogType.EMAIL_CONFIRM, EmailConfirmIcon],
  [ModalDialogType.EMAIL_OK, EmailConfirmIcon],
  [ModalDialogType.EMAIL_TAKEN, EmailConfirmIcon],
  [ModalDialogType.REGISTRATION_CONFIRMATION, EmailConfirmIcon],
  [ModalDialogType.WAITING_LIST, ReservedIcon],
  [ModalDialogType.APPOINTMENT_CANCELLED, AppointmentTakenIcon],
  [ModalDialogType.APPOINTMENT_NOT_FOUND, AppointmentTakenIcon],
  [ModalDialogType.VACCINE_OUT_OF_STOCK, AppointmentTakenIcon],
  [ModalDialogType.APP_REFRESH_NEEDED, RefreshAppIcon],
]);

const ModalDialog = ({
  type,
  closeable = false,
  onButtonClicked = () => {
    // This is intentional
  },
  email,
  optionalData,
}: TModalDialogProps) => {
  const { bannerVisible } = useAppContext();

  useEffect(() => {
    const elem = document.getElementById('root');

    elem!.style.overflow = 'hidden';
    elem!.style.height = 'inherit';

    return () => {
      elem!.style.height = '';
      elem!.style.overflow = 'unset';
    };
  }, []);

  const content = useMemo(() => {
    switch (type) {
      case ModalDialogType.APPOINTMENT_RESERVED:
        return <AppointmentReserved onButtonClick={onButtonClicked} />;
      case ModalDialogType.APPOINTMENT_TAKEN:
        return <AppointmentTaken onButtonClick={onButtonClicked} />;
      case ModalDialogType.EMAIL_CONFIRM:
        return <EmailConfirm email={email} />;
      case ModalDialogType.EMAIL_OK:
        return <EmailOk onButtonClick={onButtonClicked} />;
      case ModalDialogType.EMAIL_TAKEN:
        return <EmailTaken />;
      case ModalDialogType.REGISTRATION_CONFIRMATION:
        return <RegistrationConfirm onButtonClick={onButtonClicked} />;
      case ModalDialogType.INVALID_CODE:
        return <InvalidCode onButtonClick={onButtonClicked} />;
      case ModalDialogType.GENERIC:
        return <GenericError onButtonClick={onButtonClicked} />;
      case ModalDialogType.WAITING_LIST:
        return <WaitingList onButtonClick={onButtonClicked} />;
      case ModalDialogType.APPOINTMENT_CANCELLED:
        return (
          <AppointmentCancellation
            cancellationModalType={CancellationModalType.CANCELLED}
            onButtonClick={onButtonClicked}
          />
        );
      case ModalDialogType.APPOINTMENT_NOT_FOUND:
        return (
          <AppointmentCancellation
            cancellationModalType={CancellationModalType.APPOINTMENT_NOT_FOUND}
            onButtonClick={onButtonClicked}
          />
        );
      case ModalDialogType.VACCINE_OUT_OF_STOCK:
        return <VaccineOutOfStock onButtonClick={onButtonClicked} />;
      case ModalDialogType.INVALID_RFE_BOOKING_CODE:
        return (
          <InvalidRFEBookingCode
            onButtonClick={onButtonClicked}
            cancellationModalType={InvalidRFEBookingCodeModalType.BOOKING_CODE_INVALID}
          />
        );
      case ModalDialogType.RFE_BOOKING_CODE_REQUIRED:
        return (
          <InvalidRFEBookingCode
            onButtonClick={onButtonClicked}
            cancellationModalType={InvalidRFEBookingCodeModalType.BOOKING_CODE_REQUIRED}
          />
        );
      case ModalDialogType.VIDEO_CALL_NOT_FOUND:
        return <VideoCallNotFound onButtonClick={onButtonClicked} />;
      case ModalDialogType.VIDEO_CALL_BROWSER_UNSUPPORTED:
        return <VideoCallBrowserUnsupported onButtonClick={onButtonClicked} />;
      case ModalDialogType.APP_REFRESH_NEEDED:
        return <ModalAppRefreshNeeded />;
      case ModalDialogType.REFERRAL_QUESTION:
        return (
          <ReferralQuestion
            eventData={optionalData as TReferralQuestionModalProps['eventData']}
            onButtonClick={onButtonClicked}
          />
        );
      case ModalDialogType.PHONE_DOES_NOT_BELONG_TO_PATIENT:
        return <PhoneDoesNotBelongToPatientError onButtonClick={onButtonClicked} />;
      default:
        return null;
    }
  }, [email, onButtonClicked, optionalData, type]);

  const wrapperStyle = useMemo(() => {
    return bannerVisible ? styles.wrapperWithBanner : styles.wrapper;
  }, [bannerVisible]);

  return (
    <div className={styles.modalContainer}>
      <div className={styles.overlay} />
      <div className={wrapperStyle}>
        {closeable && (
          <div className={styles.close}>
            <img
              alt='close button in modal'
              src={CloseIcon}
              onClick={() => {
                onButtonClicked('close');
              }}
            />
          </div>
        )}
        <div className={styles.appointment}>
          {Boolean(MODAL_DIALOG_TYPE_MODEL_IMAGE_MAP.get(type)) && (
            <img alt='model dialog' className={styles.icon} src={MODAL_DIALOG_TYPE_MODEL_IMAGE_MAP.get(type)} />
          )}
          {content}
        </div>
      </div>
    </div>
  );
};

export default ModalDialog;
