import useAppContext from 'AppContext';
import { MAIN_PAGE_URL } from 'config';
import { useMemo, useState } from 'react';
import { matchPath } from 'react-router';
import { useLocation, useNavigate } from 'react-router-dom';
import { getPractice, getPractices } from 'services/practice';
import { getRFEListByCodes } from 'services/rfe';

import Logo from 'components/Logo/Logo';

import { trackEvent } from 'utils/analyticsUtils';
import { AppRoutes } from 'utils/routeUtils';

import { mapStaffData } from 'pages/Booking/AppointmentCreate/helpers/staff';

import useLanguage from 'hooks/useLanguage';
import useMobileResolution from 'hooks/useMobileResolution';

import { useNavbarTranslations } from './Navbar.translations';

import './_styles.scss';

import { DEFAULT_PRACTICE_ID } from '../../constants';

type TNavbarProps = {
  hideHomeButton?: boolean;
};
function Navbar({ hideHomeButton }: TNavbarProps) {
  const { language, setLanguage } = useLanguage();
  const location = useLocation();
  const navigate = useNavigate();

  const { context, setContext } = useAppContext();

  const [loading, setLoading] = useState(false);

  const handleChangeLanguage = async (lang: string) => {
    setLanguage(lang);

    let translatedContext = { ...context, locale: lang };
    if (context.practice.id !== DEFAULT_PRACTICE_ID) {
      const practiceData = context.practice.id ? await getPractice(context.practice.id, lang) : null;
      // since we are fetching all our practices once on the AppWrapper level
      // whenever we switch the language, we need to fetch the whole list again
      // then the mappers that are on a lower level will work with the correct data set
      // TODO: figure out the other places where we use this logic
      // ideally it should sit together with the component displaying the staff
      // and the whole logic below of practice/staff mapping can be moved away as it should not happen here
      const allPractices = await getPractices();
      const translatedDoctors = mapStaffData(practiceData?.staff);

      translatedContext = {
        ...translatedContext,
        practice: { ...translatedContext.practice, doctors: translatedDoctors },
        practices: allPractices,
      };
    }

    if (context.apptData.rfeList?.length > 0) {
      try {
        setLoading(true);
        const codes = context.apptData.rfeList.map((item) => item.code);

        const translatedRFEs = await getRFEListByCodes({
          practiceId: context.practice.id,
          codes,
          locale: lang,
          isFirstAppointment: context.apptData.isFirstAppointment,
        });

        if (translatedRFEs.length > 0) {
          const correspondedRFEs = context.apptData.rfeList.map((item) => {
            return translatedRFEs.find((translatedItem) => item.code === translatedItem.code) || item;
          });

          translatedContext = {
            ...translatedContext,
            apptData: { ...translatedContext.apptData, rfeList: correspondedRFEs },
          };
        }
        setLoading(false);
      } catch (error) {
        setLoading(false);
        console.log(error);
      }
    }

    setContext(translatedContext);
    trackEvent({ category: 'Click', action: 'Toggle locale', name: lang });
  };

  const isMobile = useMobileResolution();

  const langLabel = useMemo(() => (language === 'de' ? 'en' : 'de'), [language]);

  // Old logic needed to make changing language work on dynamic page
  // Once we improve translation handling, the following logic should be removed for a better UX
  const handleTranslationsSpecialRoutes = () => {
    if (matchPath(AppRoutes.SelectRfe(), location.pathname) || matchPath(AppRoutes.AcuteSymptom(), location.pathname)) {
      navigate(0);
    }
  };
  const translations = useNavbarTranslations();

  return (
    <header>
      <nav>
        <div style={{ flex: 1 }}>
          <a
            href={!hideHomeButton ? MAIN_PAGE_URL : window.location.href}
            className={`logo-position ${isMobile ? 'center-logo' : ''}`}>
            <Logo />
          </a>
        </div>
        {isMobile ? (
          <div
            className='lang'
            onClick={async () => {
              if (!loading && language !== langLabel) {
                await handleChangeLanguage(langLabel);
                handleTranslationsSpecialRoutes();
              }
            }}>
            {langLabel.toUpperCase()}
          </div>
        ) : (
          <ul>
            {!hideHomeButton && (
              <li className='home'>
                <a href={MAIN_PAGE_URL}>
                  <span>{translations.homeLabel}</span>
                </a>
              </li>
            )}
            <li>
              <span
                data-testid='language-control-de'
                onClick={async () => {
                  if (!loading && language !== 'de') {
                    await handleChangeLanguage('de');
                    handleTranslationsSpecialRoutes();
                  }
                }}>
                DE
              </span>
              /
              <span
                data-testid='language-control-en'
                onClick={async () => {
                  if (!loading && language !== 'en') {
                    await handleChangeLanguage('en');
                    handleTranslationsSpecialRoutes();
                  }
                }}>
                EN
              </span>
            </li>
          </ul>
        )}
      </nav>
    </header>
  );
}

export default Navbar;
